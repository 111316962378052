/* eslint-disable import/prefer-default-export */
import axiosCall from '@sf/shared/utils/axiosCall';
import { actions } from './redux';
import config from '../config';

export function postBankAggregationData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/bank-aggregation`,
        req: actions.postBankAggregationData.postBankAggregationDataReq,
        ok: actions.postBankAggregationData.postBankAggregationDataOk,
        fail: actions.postBankAggregationData.postBankAggregationDataFail,
        payload: data,
      }),
    );
  };
}
