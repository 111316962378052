import axiosCall from '@sf/shared/utils/axiosCall';
import { SCHOOL_STATUS } from '@sf/shared/utils/constants';
import config from '../config';
import { actions } from './redux';

export function postRegisterStudent(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/students`,
        req: actions.postRegisterStudent.postRegisterStudentReq,
        ok: actions.postRegisterStudent.postRegisterStudentOk,
        fail: actions.postRegisterStudent.postRegisterStudentFail,
        payload: data,
      }),
    );
  };
}

export function postPersonalData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/info`,
        req: actions.postPersonalData.postPersonalDataReq,
        ok: actions.postPersonalData.postPersonalDataOk,
        fail: actions.postPersonalData.postPersonalDataFail,
        payload: data,
      }),
    );
  };
}

export function postApplicationData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/application`,
        req: actions.postApplicationData.postApplicationDataReq,
        ok: actions.postApplicationData.postApplicationDataOk,
        fail: actions.postApplicationData.postApplicationDataFail,
        payload: data,
      }),
    );
  };
}

export function postExpensesData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/expenses`,
        req: actions.postExpensesData.postExpensesDataReq,
        ok: actions.postExpensesData.postExpensesDataOk,
        fail: actions.postExpensesData.postExpensesDataFail,
        payload: data,
      }),
    );
  };
}

// export function postEligibilityData(data) {
//   return dispatch => {
//     return dispatch(
//       axiosCall({
//         method: 'post',
//         url: `${config.apiUrl}/student-onboard/eligibility`,
//         req: actions.postEligibilityData.postEligibilityDataReq,
//         ok: actions.postEligibilityData.postEligibilityDataOk,
//         fail: actions.postEligibilityData.postEligibilityDataFail,
//         payload: data,
//       }),
//     );
//   };
// }

export function postBackgroundData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/background`,
        req: actions.postBackgroundData.postBackgroundDataReq,
        ok: actions.postBackgroundData.postBackgroundDataOk,
        fail: actions.postBackgroundData.postBackgroundDataFail,
        payload: data,
      }),
    );
  };
}

export function postMotivationData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/motivation`,
        req: actions.postMotivationData.postMotivationDataReq,
        ok: actions.postMotivationData.postMotivationDataOk,
        fail: actions.postMotivationData.postMotivationDataFail,
        payload: data,
      }),
    );
  };
}

export function postFinBackgroundData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/financial-background`,
        req: actions.postFinBackgroundData.postFinBackgroundDataReq,
        ok: actions.postFinBackgroundData.postFinBackgroundDataOk,
        fail: actions.postFinBackgroundData.postFinBackgroundDataFail,
        payload: data,
      }),
    );
  };
}

export function postJobsAvailabilityData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/job-availability`,
        req: actions.postJobsAvailabilityData.postJobsAvailabilityDataReq,
        ok: actions.postJobsAvailabilityData.postJobsAvailabilityDataOk,
        fail: actions.postJobsAvailabilityData.postJobsAvailabilityDataFail,
        payload: data,
      }),
    );
  };
}

export function postOtherData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/student-onboard/other`,
        req: actions.postOtherData.postOtherDataReq,
        ok: actions.postOtherData.postOtherDataOk,
        fail: actions.postOtherData.postOtherDataFail,
        payload: data,
      }),
    );
  };
}

export function getAllActiveSchools() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools?sort=name[asc]&status=${SCHOOL_STATUS.ACTIVE}`,
        req: actions.getAllActiveSchools.getAllSchoolsReq,
        ok: actions.getAllActiveSchools.getAllSchoolsOk,
        fail: actions.getAllActiveSchools.getAllSchoolsFail,
      }),
    );
  };
}

export function getSchoolBySlug(slug) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools/${slug}`,
        req: actions.getSchoolBySlug.getSchoolBySlugReq,
        ok: actions.getSchoolBySlug.getSchoolBySlugOk,
        fail: actions.getSchoolBySlug.getSchoolBySlugFail,
      }),
    );
  };
}

export function getAllActiveSchoolsByCountryCode(countryCode) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools?sort=name[asc]&contractCountryCode=${countryCode}&status=${SCHOOL_STATUS.ACTIVE}`,
        req: actions.getAllActiveSchoolsByCountryCode
          .getSchoolsByCountryCodeReq,
        ok: actions.getAllActiveSchoolsByCountryCode.getSchoolsByCountryCodeOk,
        fail: actions.getAllActiveSchoolsByCountryCode
          .getSchoolsByCountryCodeFail,
      }),
    );
  };
}

export function getMotivationQuestions() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/motivation-questions`,
        req: actions.getMotivationQuestions.getMotivationQuestionsReq,
        ok: actions.getMotivationQuestions.getMotivationQuestionsOk,
        fail: actions.getMotivationQuestions.getMotivationQuestionsFail,
      }),
    );
  };
}

export function getStudent(id) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/students/${id}`,
        req: actions.getStudent.getStudentReq,
        ok: actions.getStudent.getStudentOk,
        fail: actions.getStudent.getStudentFail,
      }),
    );
  };
}

export function getEducationRecords(id) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/education-records?studentId=${id}`,
        req: actions.getEducationRecords.getEducationRecordsReq,
        ok: actions.getEducationRecords.getEducationRecordsOk,
        fail: actions.getEducationRecords.getEducationRecordsFail,
      }),
    );
  };
}

export function postEducationRecord(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/education-records`,
        req: actions.postEducationRecord.postEducationRecordReq,
        ok: actions.postEducationRecord.postEducationRecordOk,
        fail: actions.postEducationRecord.postEducationRecordFail,
        payload: data,
      }),
    );
  };
}

export function putEducationRecord(id, data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/education-records/${id}`,
        req: actions.putEducationRecord.putEducationRecordReq,
        ok: actions.putEducationRecord.putEducationRecordOk,
        fail: actions.putEducationRecord.putEducationRecordFail,
        payload: data,
      }),
    );
  };
}

export function deleteEducationRecord(id) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'delete',
        url: `${config.apiUrl}/education-records/${id}`,
        req: actions.deleteEducationRecord.deleteEducationRecordReq,
        ok: actions.deleteEducationRecord.deleteEducationRecordOk,
        fail: actions.deleteEducationRecord.deleteEducationRecordFail,
      }),
    );
  };
}

export function updateStudent(id, data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/students/${id}`,
        req: actions.updateStudent.updateStudentReq,
        ok: actions.updateStudent.updateStudentOk,
        fail: actions.updateStudent.updateStudentFail,
        payload: data,
      }),
    );
  };
}

export function getBankAccounts() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/bank-accounts`,
        req: actions.getBankAccounts.getBankAccountsReq,
        ok: actions.getBankAccounts.getBankAccountsOk,
        fail: actions.getBankAccounts.getBankAccountsFail,
      }),
    );
  };
}

export function getOnfidoApplicant() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/kyc/applicants`,
        req: actions.getOnfidoApplicant.getOnfidoApplicantReq,
        ok: actions.getOnfidoApplicant.getOnfidoApplicantOk,
        fail: actions.getOnfidoApplicant.getOnfidoApplicantFail,
      }),
    );
  };
}

export function createOnfidoApplicant(applicant) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/kyc/applicants`,
        req: actions.createOnfidoApplicant.createOnfidoApplicantReq,
        ok: actions.createOnfidoApplicant.createOnfidoApplicantOk,
        fail: actions.createOnfidoApplicant.createOnfidoApplicantFail,
        payload: applicant,
      }),
    );
  };
}

export function createOnfidoSdkToken(applicant) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/kyc/sdk-token`,
        req: actions.createOnfidoSdkToken.createOnfidoSdkTokenReq,
        ok: actions.createOnfidoSdkToken.createOnfidoSdkTokenOk,
        fail: actions.createOnfidoSdkToken.createOnfidoSdkTokenFail,
        payload: applicant,
      }),
    );
  };
}

export function updateOnfidoApplicant(applicant) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/kyc/applicants`,
        req: actions.updateOnfidoApplicant.updateOnfidoApplicantReq,
        ok: actions.updateOnfidoApplicant.updateOnfidoApplicantOk,
        fail: actions.updateOnfidoApplicant.updateOnfidoApplicantFail,
        payload: applicant,
      }),
    );
  };
}

export function createOnfidoCheck(applicant) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/kyc/checks`,
        req: actions.createOnfidoCheck.createOnfidoCheckReq,
        ok: actions.createOnfidoCheck.createOnfidoCheckOk,
        fail: actions.createOnfidoCheck.createOnfidoCheckFail,
        payload: applicant,
      }),
    );
  };
}

export const patchStudents = (id, data) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'patch',
        url: `${config.apiUrl}/students/${id}`,
        req: actions.patchStudents.patchStudentsReq,
        ok: actions.patchStudents.patchStudentsOk,
        fail: actions.patchStudents.patchStudentsFail,
        payload: data,
      }),
    );
  };
};
