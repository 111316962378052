import { useState } from 'react';

export default () => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const toggleContextMenu = () => setContextMenuOpen(!contextMenuOpen);

  return {
    contextMenuOpen,
    toggleContextMenu,
  };
};
