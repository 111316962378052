import Axios from 'axios';
import axiosCall from '@sf/shared/utils/axiosCall';
import qs from 'query-string';
import { IMPERSONATE_KEY } from '@sf/shared/constants/keys';

import { STUDENT_REGISTRATION_STEP_STATUSES } from '@sf/shared/utils/constants';
import { actions } from './redux';
import config from '../config';

export function loginUser(data, res, token) {
  return async (dispatch) => {
    if (res && token) {
      localStorage.setItem('token', token);
      await dispatch(actions.login.loginUserOk(res, token));
    }
    try {
      await dispatch(actions.login.loginUserReq());
      // if it's local environment then the old login flow should work as always
      if (!config.shouldUseAuth0) {
        const response = await Axios({
          method: 'post',
          url: `${config.apiUrl}/auth/login`,
          data,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
        const { token: oldToken } = response.data.data;
        localStorage.setItem('token', oldToken);
        await dispatch(actions.login.loginUserOk(response, oldToken));
      }
    } catch (err) {
      if (err.response !== undefined) {
        await dispatch(actions.login.loginUserFail(err.response.data));
      }
      throw err;
    }
  };
}

/*
 * Redirect to login base.
 */
export const redirectToLogin = (history, overrideRedirectUrl = null) => {
  return async (dispatch) => {
    const { routes } = config;
    const currentUrl = window.location.pathname;
    const currentParam = window.location.search;
    const publicRoutes = [
      `${config.rootPath}/`,
      `${config.rootPath}${routes.login}`,
      `${config.rootPath}${routes.register}`,
      `${config.rootPath}${routes.reset}`,
      `${config.rootPath}${routes.registerStudent}`,
      `${config.rootPath}${routes.registerSchool}`,
      `${config.rootPath}${routes.registerEmployer}`,
    ];
    const allow = publicRoutes.find((open) => {
      return open === currentUrl;
    });

    /**
     * The app is constantly redirecting to the registration page by default
     * This stops it. Doesn't look good but it would take me more time to figure
     * out the logic of this app redirects.
     */
    if (currentUrl.startsWith(routes.kyc.split('/:')[0])) return;

    if (!allow) {
      localStorage.removeItem('token');
      sessionStorage.removeItem(IMPERSONATE_KEY);
      await dispatch(actions.login.redirectToLogin());
      if (
        currentUrl === '' ||
        currentUrl === routes.base ||
        overrideRedirectUrl === routes.base
      ) {
        history.push(routes.login);
      } else {
        if (currentUrl.includes('logout')) {
          history.push(routes.registerStudent);
          return;
        }
        history.push(
          `${routes.registerStudent}?redirect=${
            overrideRedirectUrl || currentUrl
          }`,
        );
      }
    } else if (currentUrl.indexOf('reset') > 0) {
      history.push(`${currentParam}`);
    } else {
      // if a school ref exists append it to localStorage
      const { ref } = qs.parse(currentParam);
      if (ref) localStorage.setItem('school', ref);
      // This is the app's default entry point
      if (window.location.pathname.includes('login')) {
        history.push(`${routes.login}${currentParam}`); // prevents redirect within the login page from auth0 sdk
        return;
      }
      history.push(`${routes.registerStudent}${currentParam}`);
    }
  };
};

/*
 * Log out user due to invalid auth token.
 */
export const logoutUserDueToInvalidToken = (history) => {
  return async (dispatch) => {
    localStorage.removeItem('token');
    await dispatch(actions.login.logoutUserDueToInvalidToken());
    if (history) {
      dispatch(redirectToLogin(history));
    }
  };
};

/*
 * Log out user user
 */
export const logoutUser = (history) => {
  return async (dispatch) => {
    localStorage.removeItem('token');
    await dispatch(actions.login.logoutUser());
    if (history) {
      dispatch(redirectToLogin(history));
    }
  };
};

/*
 * Get information related to the user.
 */
export const getUser = (newToken) => {
  return async (dispatch, getState) => {
    const impersonateKey = sessionStorage.getItem(IMPERSONATE_KEY);
    try {
      await dispatch(actions.getUser.getUserReq());
      const { token } = getState().auth.login;
      const response = await Axios({
        method: 'get',
        url: `${config.apiUrl}/users/current`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${newToken || token}`,
          ...(config.shouldUseAuth0
            ? {}
            : {
                'X-Requested-With': 'XMLHttpRequest',
              }),
          ...(config.shouldUseAuth0 ? { 'x-auth-version': 'v2' } : {}),
          ...(impersonateKey && {
            'x-user-impersonate': sessionStorage.getItem(IMPERSONATE_KEY),
          }),
        },
      });
      const { data, headers, status } = response;
      const { type, role } = data.data;
      await dispatch(
        actions.getUser.getUserOk(data, { headers, status }, { role, type }),
      );
      return { response, role, type };
    } catch (err) {
      if (err.response !== undefined) {
        await dispatch(actions.getUser.getUserFail(err.response.data));
        await dispatch(logoutUserDueToInvalidToken());
      }
      throw err;
    }
  };
};

const getStudentIntroUrl = () => {
  const { routes } = config;
  return routes.student[10];
};

/*
 * Get a redirect URL for a user, depending on his authorization attributes.
 */
export const getRedirectUrl = () => {
  return (dispatch, getState) => {
    const { routes, userTypes } = config;
    const { response } = getState().auth.getUser;
    let registrationStep = 0;

    if (response && response.data.registrationStep) {
      registrationStep =
        response.data.registrationStep ===
        STUDENT_REGISTRATION_STEP_STATUSES.JOB_AVAILABILITY
          ? STUDENT_REGISTRATION_STEP_STATUSES.CONGRATS
          : response.data.registrationStep;
    } else registrationStep = 0;

    const userType = response ? response.data.type : null;
    const type = userType === 'self' ? 'admin' : userType;
    if (!userTypes.includes(userType)) {
      return routes.login;
    }

    if (type === 'school') {
      return routes.school.applications;
    }

    if (registrationStep === 0 && type === 'student') {
      return getStudentIntroUrl();
    }

    return routes[type][registrationStep];
  };
};

export const initializeUser = (token) => {
  return async (dispatch) => {
    const response = await dispatch(getUser(token));
    const user = response.response.data.data;

    return user;
  };
};

export function postPasswordReset(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/auth/password-reset`,
        req: actions.postPasswordReset.postPasswordResetReq,
        ok: actions.postPasswordReset.postPasswordResetOk,
        fail: actions.postPasswordReset.postPasswordResetFail,
        payload: data,
      }),
    );
  };
}

export function updatePassword(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/auth/password-reset`,
        req: actions.updatePassword.updatePasswordReq,
        ok: actions.updatePassword.updatePasswordOk,
        fail: actions.updatePassword.updatePasswordFail,
        payload: data,
      }),
    );
  };
}
