import { combineReducers } from 'redux';
import * as getEducationFields from './getEducationFields';
import * as getCities from './getCities';
import * as getCountries from './getCountries';
import * as getLanguages from './getLanguages';
import * as getLanguageOptions from './getLanguageOptions';
import * as getAllStudents from './getAllStudents';
import * as updateDocuments from './updateDocuments';
import * as postDocuments from './postDocuments';
import * as postApplicationReviews from './postApplicationReviews';
import * as notification from './notification';
import * as getIndustries from './getIndustries';
import * as getEducationTypes from './getEducationTypes';
import * as getStudentDataById from './getStudentDataById';
import * as getApplicationFilters from './getApplicationFilters';
import * as getApplications from './getApplications';
import * as getApplicationReviews from './getApplicationReviews';
import * as getEmploymentStatus from './getEmploymentStatus';
import * as getWorkPermitOptions from './getWorkPermitOptions';
import * as closeApplication from './closeApplication';
import * as getDocuments from './getDocuments';
// import * as getContractStatuses from './getContractStatuses';
import * as triggerEvent from './triggerEvent';
import * as getApplicationUpdateRequests from './getApplicationUpdateRequests';
import * as postApplicationUpdateRequests from './postApplicationUpdateRequests';
import * as deleteApplicationUpdateRequests from './deleteApplicationUpdateRequests';
import * as getKnownVulnerabilitiesOptions from './getKnownVulnerabilitiesOptions';

const reducer = combineReducers({
  getEducationFields: getEducationFields.reducer,
  getCities: getCities.reducer,
  getCountries: getCountries.reducer,
  getLanguages: getLanguages.reducer,
  getLanguageOptions: getLanguageOptions.reducer,
  getAllStudents: getAllStudents.reducer,
  updateDocuments: updateDocuments.reducer,
  postDocuments: postDocuments.reducer,
  postApplicationReviews: postApplicationReviews.reducer,
  notification: notification.reducer,
  getIndustries: getIndustries.reducer,
  getEducationTypes: getEducationTypes.reducer,
  getStudentDataById: getStudentDataById.reducer,
  getApplicationFilters: getApplicationFilters.reducer,
  getApplications: getApplications.reducer,
  getApplicationReviews: getApplicationReviews.reducer,
  getEmploymentStatus: getEmploymentStatus.reducer,
  getWorkPermitOptions: getWorkPermitOptions.reducer,
  deleteApplication: closeApplication.reducer,
  getDocuments: getDocuments.reducer,
  // getContractStatuses: getContractStatuses.reducer,
  triggerEvent: triggerEvent.reducer,
  getApplicationUpdateRequests: getApplicationUpdateRequests.reducer,
  postApplicationUpdateRequests: postApplicationUpdateRequests.reducer,
  deleteApplicationUpdateRequests: deleteApplicationUpdateRequests.reducer,
  getKnownVulnerabilitiesOptions: getKnownVulnerabilitiesOptions.reducer,
});

const actions = {
  getEducationFields: getEducationFields.actions,
  getCities: getCities.actions,
  getCountries: getCountries.actions,
  getLanguages: getLanguages.actions,
  getLanguageOptions: getLanguageOptions.actions,
  getAllStudents: getAllStudents.actions,
  updateDocuments: updateDocuments.actions,
  postDocuments: postDocuments.actions,
  notification: notification.actions,
  getIndustries: getIndustries.actions,
  getEducationTypes: getEducationTypes.actions,
  getStudentDataById: getStudentDataById.actions,
  getApplicationFilters: getApplicationFilters.actions,
  getApplications: getApplications.actions,
  postApplicationReviews: postApplicationReviews.actions,
  getApplicationReviews: getApplicationReviews.actions,
  getEmploymentStatus: getEmploymentStatus.actions,
  getWorkPermitOptions: getWorkPermitOptions.actions,
  closeApplication: closeApplication.actions,
  getDocuments: getDocuments.actions,
  // getContractStatuses: getContractStatuses.actions,
  triggerEvent: triggerEvent.actions,
  getApplicationUpdateRequests: getApplicationUpdateRequests.actions,
  postApplicationUpdateRequests: postApplicationUpdateRequests.actions,
  deleteApplicationUpdateRequests: deleteApplicationUpdateRequests.actions,
  getKnownVulnerabilitiesOptions: getKnownVulnerabilitiesOptions.actions,
};

const shapes = {
  notifications: notification.defaultNotificationShape,
};

export { actions, reducer, shapes };
