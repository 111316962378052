import styled from 'styled-components';
import config from '@sf/config';

const { colors } = config;

export const Title = styled.h1`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '48px')};
  font-weight: 700;
  line-height: 1.25;
  position: relative;
  width: fit-content;
  display: initial;
  margin: 20px 0px 10px;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => (props.color ? props.color : colors.appPurple)};
  letter-spacing: -0.01em;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '24px')};
  font-weight: 700;
  line-height: 1.25;
  position: relative;
  width: fit-content;
  margin: 0 0 24px;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => (props.color ? props.color : colors.appPurple)};
  letter-spacing: -0.01em;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const TagText = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-family: ${(props) => props.theme.fontFamily};
  background: ${(props) => (props.color ? props.color : colors.appPurple)};
  color: #fff;
  padding: 1px 11px;
  border-radius: 10px;
  margin-left: 10px;
`;

export const DarkTitle = styled.h2`
  font-size: 14px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  width: fit-content;
  text-transform: uppercase;
  margin: 20px 0px 10px;
  font-family: ${(props) => props.theme.fontFamily};
  padding: 2px 6px;
  color: ${(props) => (props.color ? props.color : colors.white)};
  background-color: ${colors.appDarkPurple};
`;

export const Text = styled.p`
  display: flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: ${(props) => props.theme.fontFamily};
  margin: ${(props) => (props.margin ? props.margin : '5px 0px')};
  color: ${(props) => (props.color ? props.color : colors.appPurple)};

  :focus {
    box-shadow: 0 0 0 4px ${colors.appLightBlue};
    outline: 0;
  }
`;

export const QuestionText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: ${(props) => props.theme.fontFamily};
  text-transform: ${(props) => (props.transform ? props.transform : 'initial')};
  margin: ${(props) => `${props.margin ?? '5px 0px'};`}
  color: ${(props) => (props.color ? props.color : colors.appPurple)};
`;

export const ResultText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: ${(props) => props.theme.fontFamily};
  margin: 5px 0px;
  color: ${colors.appPurple};
`;

export const SimpleRoundIcon = styled.div`
  display: block;
  color: #fff;
  background-color: ${(props) => props.bgColor};
  width: 16px;
  line-height: 16px;
  font-size: 14px;
  text-align: center;
  height: 16px;
  border-radius: 100%;
  cursor: pointer;
  margin-left: 5px;

  :focus {
    box-shadow: 0 0 0 2px ${colors.appBlue};
    outline: 0;
  }
`;
