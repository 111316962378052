import React from 'react';
import { Redirect } from 'react-router-dom';
import useActions from '@sf/shared/hooks/useActions';
import * as api from '../api';

/**
 * Return a react-router-dom Redirect component that redirect the user to an appropriate destination.
 */
const RedirectToStatusUrl = ({ from, ...props }) => {
  const authApiActions = useActions(api);
  const redirectUrl = authApiActions.getRedirectUrl();
  return <Redirect from={from} to={redirectUrl} {...props} />;
};

export default RedirectToStatusUrl;
