import { combineReducers } from 'redux';
import * as getUser from './getUser';
import * as postPasswordReset from './postPasswordReset';
import * as updatePassword from './updatePassword';
import * as login from './login';

const reducer = combineReducers({
  getUser: getUser.reducer,
  postPasswordReset: postPasswordReset.reducer,
  updatePassword: updatePassword.reducer,
  login: login.reducer,
});

const actions = {
  getUser: getUser.actions,
  postPasswordReset: postPasswordReset.actions,
  updatePassword: updatePassword.actions,
  login: login.actions,
};

export { actions, reducer };
