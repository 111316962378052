import { STUDENT_REGISTRATION_STEP_STATUSES } from '@sf/shared/utils/constants';
import normalizeLanguage from './shared/utils/normalizeLanguage';

const base = '';

// eslint-disable-next-line no-undef
window.SF_WEB_VERSION = `${process.env.npm_package_version}@${___COMMIT___}`;

const config = {
  isWl: false,
  logoOrientation: 'horizontal',
  logo:
    window.localStorage.getItem('school') !== 'null' &&
    window.localStorage.getItem('school') !== null
      ? `https://assets.studentfinance.com/images/${window.localStorage.getItem(
          'school',
        )}.png`
      : null,
  defaultPrivacyLink:
    'https://www.studentfinance.com/es/support/privacy-policy',
  ukPrivacyLink: 'https://www.studentfinance.com/uk/support/privacy-policy',
  termsLink: 'https://www.studentfinance.com/uk/support/terms-conditions',
  school: window.localStorage.getItem('school'),
  // schoolId: 'BD230DF7-685E-4214-81C5-4C873FD7B233',
  rootPath: '',
  language:
    window.localStorage.getItem('language') ||
    normalizeLanguage(navigator.language),

  roles: {
    10: 'admin',
    20: 'user',
  },

  userTypes: ['self', 'student', 'school', 'employer'],

  studentOnboardSteps: STUDENT_REGISTRATION_STEP_STATUSES.CONGRATS,
  schoolOnboardSteps: 30,
  employerOnboardSteps: 20,

  nodeEnv: process.env.NODE_ENV,
  targetEnv: process.env.TARGET_ENV,

  apiUrl: {
    local: 'http://localhost:3000/api',
    development: 'https://api.dev.studentfinance.com/api',
    staging: 'https://api.staging.studentfinance.com/api',
    production: 'https://api.studentfinance.com/api',
  }[process.env.TARGET_ENV],

  host: {
    local: 'http://localhost:5050',
    development: 'https://app.dev.studentfinance.com',
    staging: 'https://app.staging.studentfinance.com',
    production: 'https://app.studentfinance.com',
  }[process.env.TARGET_ENV],

  studentDashboardUrl: {
    local: 'http://localhost:3001',
    development: 'https://students.dev.studentfinance.com',
    staging: 'https://students.staging.studentfinance.com',
    production: 'https://students.studentfinance.com',
  }[process.env.TARGET_ENV],

  authAudience: {
    local: 'https://api.dev.studentfinance.com',
    development: 'https://api.dev.studentfinance.com',
    staging: 'https://api.staging.studentfinance.com',
    production: 'https://api.studentfinance.com',
  }[process.env.TARGET_ENV],

  // colors
  colors: {
    white: '#FFFFFF',
    transparentWhite: 'rgba(255, 255, 255, 0.15)',
    black: '#000000',
    almostBlack: '#1e1e1e',
    background: '#F7F7FA',
    appBlue: '#278EFF',
    appRed: '#FF4646',
    appLightRed: '#FF7A55',
    appGreen: '#00BD3B',
    appLightGreen: '#D9F5E2',
    appLightBlue: '#D8EAFF',
    appLighterBlue: '#F8F9FF',
    appPurple: '#2E024F',
    appDarkPurple: '#2B2855',
    appTextPurple: '#2E024F',
    secondaryPurple: '#7F6492',
    gray: '#E8EEF4',
    textGray: '#8C8C8C',
    textOrange: '#FF9320',
    lightGray: '#DAD9DB',
    lighterGray: '#D1D1D1',
    error: '#F00F00',
    lightPurple: '#EAE5ED',
    lightGreen: '#CBDFBE',
    lightYellow: '#FFEEB8',
    lightBlue: '#278FFF',
    'green-800': '#396D13',
    'green-500': '#5B9C2D',
    'green-100': '#CBDFBE',
    'red-500': '#f00000',
    dashboard: {
      APPLICATION_STATUS_STARTED: '#93B4B8',
      APPLICATION_STATUS_COMPLETED: '#6CA3AD',
      APPLICATION_STATUS_EXPIRED: '#A195A3',
      APPLICATION_STATUS_DROPPED: '#A1B08D',
      APPLICATION_STATUS_UNDER_REVIEW: '#ABA489',
      APPLICATION_STATUS_REQUIRES_STUDENT_FEEDBACK: '#BF9C82',
      APPLICATION_STATUS_REQUIRES_SCHOOL_FEEDBACK: '#BF9C82',
      APPLICATION_STATUS_ACCEPTED: '#00BD3B',
      APPLICATION_STATUS_REJECTED: '#FF7A55',
      APPLICATION_STATUS_DELETED: '#FF7A55',
    },
    badge: {
      color: {
        error: '#000000',
        warning: '#FFFFFF',
        success: '#FFFFFF',
      },
      bgcolor: {
        error: '#F00F00',
        warning: '#FFEEB8',
        success: '#CBDFBE',
      },
    },
  },

  // sizes
  sizes: {
    lineHeight: {
      small: '24px',
      medium: '32px',
      large: '40px',
    },
    icon: {
      small: '16',
      medium: '20',
      large: '24',
    },
    font: {
      small: '14px',
      medium: '16px',
      large: '18px',
    },
  },

  // #region Routes
  routes: {
    base: `${base}`,
    login: `${base}/login`,
    logout: `${base}/logout`,
    reset: `${base}/reset`,
    register: `${base}/register`,
    registerStudent: `${base}/register/student`,
    registerSchool: `${base}/register/school`,
    registerEmployer: `${base}/register/employer`,
    kyc: `${base}/kyc/:slug`,
    admin: {
      base: `${base}/admin`,
      student: {
        base: `${base}/admin/dashboard/student`,
        docview: `${base}/admin/dashboard/docview/:id`,
      },
      school: {
        base: `${base}/admin/dashboard/school`,
        details: `${base}/admin/dashboard/school/:id`,
      },
      servicing: {
        base: `${base}/admin/dashboard/servicing`,
        details: `${base}/admin/dashboard/servicing/:userId`,
      },
      docusign: {
        base: `${base}/admin/dashboard/docusign`,
      },
      // jobs: {
      //   base: `${base}/admin/dashboard/jobs`,
      // },
      // TODO: I can't remove this. Why!?
      0: `${base}/admin/dashboard/student`,
    },
    student: {
      base: `${base}/student`,
      [STUDENT_REGISTRATION_STEP_STATUSES.COURSE_SELECTION]: `${base}/student/onboard/course`,
      [STUDENT_REGISTRATION_STEP_STATUSES.PERSONAL_DATA]: `${base}/student/onboard/data`,
      [STUDENT_REGISTRATION_STEP_STATUSES.KYC]: `${base}/student/onboard/identification`,
      [STUDENT_REGISTRATION_STEP_STATUSES.WORK_PERMIT]: `${base}/student/onboard/workPermit`,
      [STUDENT_REGISTRATION_STEP_STATUSES.EXPERIENCE_AND_EDUCATION]: `${base}/student/onboard/background`,
      [STUDENT_REGISTRATION_STEP_STATUSES.FINANCIAL_BACKGROUND]: `${base}/student/onboard/financial-background`,
      [STUDENT_REGISTRATION_STEP_STATUSES.LIVING_EXPENSES]: `${base}/student/onboard/living`,
      [STUDENT_REGISTRATION_STEP_STATUSES.ONBOARD_JOBS]: `${base}/student/onboard/jobs`,
      [STUDENT_REGISTRATION_STEP_STATUSES.CONGRATS]: `${base}/student/onboard/congrats`,
      [STUDENT_REGISTRATION_STEP_STATUSES.STUDYING]: `${base}/student/onboard/studying`,
    },
    school: {
      base: `${base}/school`,
      0: `${base}/school/onboard/data`,
      10: `${base}/school/onboard/data`,
      20: `${base}/school/onboard/contacts`,
      30: `${base}/school/onboard/courses`,
      40: `${base}/school/dashboard`,
      profile: `${base}/school/profile`,
      applications: `${base}/school/applications`,
      candidates: `${base}/school/candidates`,
      reportRequests: `${base}/school/report-requests`,
      graduateReports: `${base}/school/graduate-reports`,
      candidateReview: `${base}/school/candidate/review/:id`,
      classes: `${base}/school/classes`,
      isas: `${base}/school/isas`,
      isaTerms: `${base}/school/isa-terms`,
      studentRepayments: `${base}/school/student-repayments`,
      paymentsReport: `${base}/school/payments-report`,
      applicationAlreadyProcessed: `${base}/school/application-already-processed`,
      notFound: `${base}/school/not-found`,
    },
    employer: {
      base: `${base}/employer`,
      10: `${base}/employer/onboard/data`,
      20: `${base}/employer/onboard/jobs`,
    },
  },

  // Application status ids
  applicationStatusIds: {
    groups: {
      APPLICATION_OPEN: '10,11,12,13,14,20,21,22,30,31',
      APPLICATION_WITH_ISA: '40,50',
    },
  },

  // Currency
  currency: [
    {
      label: 'EUR',
      config: {
        locale: 'en-US',
        formats: {
          number: {
            EUR: {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
        },
      },
    },
    {
      label: 'GBP',
      config: {
        locale: 'en-US',
        formats: {
          number: {
            GBP: {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
        },
      },
    },
  ],

  // Onfido
  onfido: {
    referrer: {
      local: '*://*/*',
      development: 'https://app.dev.studentfinance.com/*/*',
      staging: 'https://app.staging.studentfinance.com/*/*',
      production: 'https://app.studentfinance.com/*/*',
    }[process.env.TARGET_ENV],
  },

  // feature flags
  enableLoanTypeSelector: process.env.ENABLE_LOAN_TYPE_SELECTOR === 'true',
  shouldUseAuth0: process.env.SHOULD_USE_AUTH0 === 'true',

  // auth0
  auth0: {
    domain: {
      local: process.env.AUTH0_DOMAIN,
      development: process.env.AUTH0_DOMAIN,
      staging: process.env.AUTH0_DOMAIN,
      production: process.env.AUTH0_DOMAIN,
    }[process.env.TARGET_ENV],
    clientId: {
      local: process.env.AUTH0_CLIENT_ID,
      development: process.env.AUTH0_CLIENT_ID,
      staging: process.env.AUTH0_CLIENT_ID,
      production: process.env.AUTH0_CLIENT_ID,
    }[process.env.TARGET_ENV],
    redirectUri: {
      local: process.env.AUTH0_REDIRECT_URI,
      development: process.env.AUTH0_REDIRECT_URI,
      staging: process.env.AUTH0_REDIRECT_URI,
      production: process.env.AUTH0_REDIRECT_URI,
    }[process.env.TARGET_ENV],
  },

  launchDarkly: {
    clientId: process.env.LAUNCHDARKLY_CLIENT_ID,
  },

  tink: {
    clientId: process.env.TINK_CLIENT_ID,
  },
  elfsight: {
    appId: process.env.ELFSIGHT_APP_ID,
  },
};

export default config;
