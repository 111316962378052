import React, { lazy } from 'react';
import { hot } from 'react-hot-loader/root';
import { Route } from 'react-router-dom';
import SuspenseWrapper from '@sf/shared/components/SuspenseWrapper';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';
import config from '../../config';
import Notification from './Notification';
import '@babel/polyfill';
import ErrorBoundaryMessage from './ErrorBoundaryMessage';

const { routes } = config;
// TODO: TM - EXPORT THIS TO ITS OWN FILE AFTER SENDING THE CONFIG RULES FROM WHITE lABEL
export const GlobalStyle = createGlobalStyle`
  :root {
    --border-radius-s: 4px;
    --border-radius-m: 8px;
    --border-radius-l: 12px;
  }

  @media (min-width: 769px) {
    html, body, #root {
      height: 100%;
    }
  }

  body {
    padding: 0;
    margin: 0;
    font-family: ${(props) => props.theme.fontFamily};
    background-color: ${(props) => props.theme.backgroundColor};
  }
`;

const AuthWrapper = lazy(() =>
  import(
    /* webpackChunkName: "AuthWrapper" */ '@sf/auth/components/AuthWrapper'
  ),
);
const RegisterStudent = lazy(() =>
  import(
    /* webpackChunkName: "RegisterStudent" */ '@sf/student/register/components/RegisterStudent'
  ),
);
// const RegisterSchool = lazy(() =>
//   import(
//     /* webpackChunkName: "RegisterSchool" */ '@sf/school/register/components/RegisterSchool'
//   ),
// );
// const RegisterEmployer = lazy(() =>
//   import(
//     /* webpackChunkName: "RegisterEmployer" */ '@sf/employer/register/components/RegisterEmployer'
//   ),
// );
const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ '@sf/auth/components/Login'),
);

const AuthLogin = lazy(() =>
  import(/* webpackChunkName: "AuthLogin" */ '@sf/auth/components/Auth0Login'),
);

const Logout = lazy(() =>
  import(/* webpackChunkName: "Logout" */ '@sf/auth/components/Logout'),
);

const Register = lazy(() =>
  import(/* webpackChunkName: "Register" */ '@sf/auth/components/Register'),
);
const KYC = lazy(() =>
  import(/* webpackChunkName: "KYC" */ '@sf/student/onboard/kyc/KYC'),
);

const ResetPass = lazy(() =>
  import(/* webpackChunkName: "ResetPass" */ '@sf/auth/components/ResetPass'),
);

/**
 * Entry point component for the application.
 */
const App = () => {
  return (
    <ThemeProvider
      theme={{ fontFamily: 'Poppins', backgroundColor: '#f7f7fa' }}
    >
      <ErrorBoundaryMessage
        reloadOnErrorNameMatching={/ChunkLoadError/}
        onError={(error) => Sentry.captureException(error)}
      >
        <SuspenseWrapper>
          <Notification />
          <GlobalStyle />
          {/* if the TARGET_ENV is local we use the old account create flow, otherwise redirect to auth0 */}
          <Route
            path={routes.registerStudent}
            // only enables once auth0 signup is ready on the backend
            // component={config.shouldUseAuth0 ? AuthLogin : RegisterStudent}
            component={RegisterStudent}
            exact
          />
          {/* <Route path={routes.registerSchool} component={RegisterSchool} exact />
        <Route
          path={routes.registerEmployer}
          component={RegisterEmployer}
          exact
        /> */}
          {/* if the TARGET_ENV is local we use the old login flow */}
          <Route
            path={routes.login}
            component={config.shouldUseAuth0 ? AuthLogin : Login}
            exact
          />
          {/* if the TARGET_ENV is local we use the old account create flow, otherwise redirect to auth0 */}
          <Route
            path={routes.register}
            // only enables once auth0 signup is ready on the backend
            // component={config.shouldUseAuth0 ? AuthLogin : Register}
            component={Register}
            exact
          />
          <Route path={routes.logout} component={Logout} />
          {config.shouldUseAuth0 ? null : (
            <Route path={routes.reset} component={ResetPass} />
          )}
          <Route component={AuthWrapper} />
          <Route path={routes.kyc} component={KYC} exact />
        </SuspenseWrapper>
      </ErrorBoundaryMessage>
    </ThemeProvider>
  );
};

export default hot(App);
