export const LOAN_TYPE_ISA = 1;
export const LOAN_TYPE_FIX_PAY = 2;

export const FIX_PAY_MINIMUM_INCOME = 148400;

export const LOAN_TYPES = {
  ISA: {
    id: LOAN_TYPE_ISA,
    name: 'Isa',
  },
  FIX_PAY: {
    id: LOAN_TYPE_FIX_PAY,
    name: 'Fix pay',
  },
};

export const SCORE_TAB_KEYS = {
  ELIGIBILITY_VIEW: 'Eligibility_View',
  PERSONAL_DATA_VIEW: 'Personal_Data_View',
  PROFESSIONAL_BACKGROUND_VIEW: 'Professional_BackgroundView',
  FINANCIAL_BACKGROUND_VIEW: 'Financial_Background_View',
  DOCUMENTS_VIEW: 'Documents_View',
  SCHOOL_REVIEW_VIEW: 'School_Review_View',
  JOBS_AVAILABILITY_VIEW: 'Jobs_Availability_View',
  CONTRACT_DETAILS_VIEW: 'Contract_Details_View',
};

export const STUDENT_REGISTRATION_STEP_STATUSES = {
  COURSE_SELECTION: 10,
  PERSONAL_DATA: 20,
  KYC: 22,
  WORK_PERMIT: 25,
  EXPERIENCE_AND_EDUCATION: 30,
  FINANCIAL_BACKGROUND: 50,
  LIVING_EXPENSES: 60,
  ONBOARD_JOBS: 65,
  JOB_AVAILABILITY: 65,
  /**
   * @deprecated this step is not longer being used anymore
   */
  CONTRACT: 67,
  CONGRATS: 70,
  STUDYING: 80,
};

export const APPLICATION_STATUSES = {
  STARTED: 10,
  COMPLETED: 11,
  EXPIRED: 12,
  DROPPED: 13,
  CANCELLED: 14,
  ABANDONED: 15,
  UNDER_REVIEW: 20,
  REQUIRES_SCHOOL_FEEDBACK: 21,
  REQUIRES_STUDENT_FEEDBACK: 22,
  ACCEPTED: 30,
  REJECTED: 31,
  SCHOOL_APPROVED: 32,
  SCHOOL_REJECTED: 33,
  CONTRACT_SENT: 40,
  CONTRACT_VOIDED: 41,
  CONTRACT_WITHDRAWAL: 42,
  CONTRACT_REPAID: 44,
  CONTRACT_SIGNED: 50,
};

export const SCHOOL_STATUS = {
  ACTIVE: 10,
  INACTIVE: 20,
};
