import React from 'react';
import config from '@sf/config';
import { Route, Redirect } from 'react-router-dom';

const { routes } = config;
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
