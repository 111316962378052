import { combineReducers } from 'redux';
import * as getDocuments from './getDocuments';
import * as getScoreItems from './getScoreItems';
import * as getScoreLabels from './getScoreLabels';
import * as postScoreItemReviews from './postScoreItemReviews';
import * as getReviews from './getReviews';
import * as getAplicationStatuses from './getAplicationStatuses';
import * as getClosedApplicationStatuses from './getClosedApplicationStatuses';
import * as getSchoolApplicationReview from './getSchoolApplicationReview';
import * as getAllSchools from './getAllSchools';
// import * as getContracts from './getContracts';
// import * as postContracts from './postContracts';
// import * as patchContracts from './patchContracts';
import * as getSchool from './getSchool';
import * as postCourseCohort from './postCourseCohort';
import * as patchCourseCohort from './patchCourseCohort';
import * as putStudentBankData from './putStudentBankData';
import * as patchApplicationData from './patchApplicationData';
import * as getIncomes from './getIncomes';
import * as postIncomes from './postIncomes';
import * as putIncomes from './putIncomes';
import * as getRepayments from './getRepayments';
import * as postRepayments from './postRepayments';
import * as putRepayments from './putRepayments';
import * as getDocumentContent from './getDocumentContent';
import * as getApplicationById from './getApplicationById';
import * as putKycProfile from './putKycProfile';
import * as patchUsers from './patchUsers';
import * as putApplicationCourseData from './putApplicationCourseData';
import * as postAdminQueue from './postAdminQueue';
import * as getStudentIncomeDetails from './getStudentIncomeDetails';

const reducer = combineReducers({
  getDocuments: getDocuments.reducer,
  getScoreItems: getScoreItems.reducer,
  getScoreLabels: getScoreLabels.reducer,
  postScoreItemReviews: postScoreItemReviews.reducer,
  getReviews: getReviews.reducer,
  getAplicationStatuses: getAplicationStatuses.reducer,
  getClosedApplicationStatuses: getClosedApplicationStatuses.reducer,
  getSchoolApplicationReview: getSchoolApplicationReview.reducer,
  getAllSchools: getAllSchools.reducer,
  // getContracts: getContracts.reducer,
  // postContracts: postContracts.reducer,
  // patchContracts: patchContracts.reducer,
  getSchool: getSchool.reducer,
  postCourseCohort: postCourseCohort.reducer,
  patchCourseCohort: patchCourseCohort.reducer,
  putStudentBankData: putStudentBankData.reducer,
  patchApplicationData: patchApplicationData.reducer,
  getIncomes: getIncomes.reducer,
  postIncomes: postIncomes.reducer,
  putIncomes: putIncomes.reducer,
  getRepayments: getRepayments.reducer,
  postRepayments: postRepayments.reducer,
  putRepayments: putRepayments.reducer,
  getDocumentContent: getDocumentContent.reducer,
  getApplicationById: getApplicationById.reducer,
  putKycProfile: putKycProfile.reducer,
  patchUsers: patchUsers.reducer,
  putApplicationCourseData: putApplicationCourseData.reducer,
  postAdminQueue: postAdminQueue.reducer,
  getStudentIncomeDetails: getStudentIncomeDetails.reducer,
});

const actions = {
  getDocuments: getDocuments.actions,
  getScoreItems: getScoreItems.actions,
  getScoreLabels: getScoreLabels.actions,
  postScoreItemReviews: postScoreItemReviews.actions,
  getReviews: getReviews.actions,
  getAplicationStatuses: getAplicationStatuses.actions,
  getClosedApplicationStatuses: getClosedApplicationStatuses.actions,
  getSchoolApplicationReview: getSchoolApplicationReview.actions,
  getAllSchools: getAllSchools.actions,
  // getContracts: getContracts.actions,
  // postContracts: postContracts.actions,
  // patchContracts: patchContracts.actions,
  getSchool: getSchool.actions,
  postCourseCohort: postCourseCohort.actions,
  patchCourseCohort: patchCourseCohort.actions,
  putStudentBankData: putStudentBankData.actions,
  patchApplicationData: patchApplicationData.actions,
  getIncomes: getIncomes.actions,
  postIncomes: postIncomes.actions,
  putIncomes: putIncomes.actions,
  getRepayments: getRepayments.actions,
  postRepayments: postRepayments.actions,
  putRepayments: putRepayments.actions,
  getDocumentContent: getDocumentContent.actions,
  getApplicationById: getApplicationById.actions,
  putKycProfile: putKycProfile.actions,
  patchUsers: patchUsers.actions,
  putApplicationCourseData: putApplicationCourseData.actions,
  postAdminQueue: postAdminQueue.actions,
  getStudentIncomeDetails: getStudentIncomeDetails.actions,
};

export { actions, reducer };
