import axiosCall from '@sf/shared/utils/axiosCall';
import { actions } from './redux';
import config from '../config';

// create calls
export function postRegisterEmployer(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}employer/register`,
        req: actions.postRegisterEmployer.postRegisterEmployerReq,
        ok: actions.postRegisterEmployer.postRegisterEmployerOk,
        fail: actions.postRegisterEmployer.postRegisterEmployerFail,
        payload: data,
      }),
    );
  };
}

export function postEmployerBasicData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}employer-onboard/info`,
        req: actions.postEmployerBasicData.postEmployerBasicDataReq,
        ok: actions.postEmployerBasicData.postEmployerBasicDataOk,
        fail: actions.postEmployerBasicData.postEmployerBasicDataFail,
        payload: data,
      }),
    );
  };
}

export function postJobs(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}employer-onboard/jobs`,
        req: actions.postJobs.postJobsReq,
        ok: actions.postJobs.postJobsOk,
        fail: actions.postJobs.postJobsFail,
        payload: data,
      }),
    );
  };
}

export function getSkills() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}employer-onboard/skills`,
        req: actions.getSkills.getSkillsReq,
        ok: actions.getSkills.getSkillsOk,
        fail: actions.getSkills.getSkillsFail,
      }),
    );
  };
}

export function getJobs() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}employer-onboard/jobs`,
        req: actions.getJobs.getJobsReq,
        ok: actions.getJobs.getJobsOk,
        fail: actions.getJobs.getJobsFail,
      }),
    );
  };
}
