import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '@sf/shared/utils/ProtectedRoute';
import SuspenseWrapper from '@sf/shared/components/SuspenseWrapper';
import config from '@sf/config';
import LoadingSpinner from '@sf/shared/loaders/LoadingSpinner';
import RedirectToStatusUrl from './RedirectToStatusUrl';
import useAuthWrapperContainer from '../containers/useAuthWrapperContainer';

const StudentRoutes = lazy(() =>
  import(/* webpackChunkName: "StudentRoutes" */ '@sf/student/StudentRoutes'),
);

const SchoolRoutes = lazy(() =>
  import(
    /* webpackChunkName: "SchoolRoutes" */ '@sf/school/routes/SchoolRoutes'
  ),
);

const EmployerRoutes = lazy(() =>
  import(
    /* webpackChunkName: "EmployerRoutes" */ '@sf/employer/EmployerRoutes'
  ),
);

const AdminRoutes = lazy(() =>
  import(/* webpackChunkName: "AdminRoutes" */ '@sf/admin/routes/AdminRoutes'),
);

/**
 * Wrapper component for logged-in section of the application.
 */
const AuthWrapper = () => {
  const { ready } = useAuthWrapperContainer();
  const { routes } = config;
  return (
    <SuspenseWrapper fallback={LoadingSpinner()} isReadyToRender={ready}>
      <Switch>
        <RedirectToStatusUrl from={`${routes.base}/`} exact />
        <ProtectedRoute path={routes.student.base} component={StudentRoutes} />
        <ProtectedRoute path={routes.school.base} component={SchoolRoutes} />
        <ProtectedRoute
          path={routes.employer.base}
          component={EmployerRoutes}
        />
        <ProtectedRoute path={routes.admin.base} component={AdminRoutes} />
      </Switch>
    </SuspenseWrapper>
  );
};

export default AuthWrapper;
