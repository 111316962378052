import React from 'react';
import styled from 'styled-components';
import { ButtonPrimary } from '../components/Buttons';
import { Text, Title } from './Typography';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  text-align: center;

  & h1 {
    font-size: 62px;
    margin: 0;
  }
`;

export default ({ title, description }) => {
  return (
    <PageContainer>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <ButtonPrimary
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Return home
      </ButtonPrimary>
    </PageContainer>
  );
};
