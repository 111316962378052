import React from 'react';
import styled from 'styled-components';

const LoadingSpinnerParent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const LoadingSpinnerContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingSpinner = () => (
  <LoadingSpinnerParent>
    <LoadingSpinnerContent>Loading...</LoadingSpinnerContent>
  </LoadingSpinnerParent>
);

export default LoadingSpinner;
