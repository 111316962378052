import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import store from './store';
import { App } from './app';
import config from './config';

if (process.env.TARGET_ENV !== 'local') {
  Sentry.init({
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
    environment: process.env.TARGET_ENV,
    dsn: 'https://a8360f7d115f4fd2926cd301a76e94b6@sentry.io/5181593',
    replaysSessionSampleRate:
      process.env.TARGET_ENV === 'development' ? 1 : 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [new Sentry.Replay({})],
  });
}

function toUTCDateString() {
  return new Date(
    this.getUTCFullYear(),
    this.getUTCMonth(),
    this.getUTCDate(),
  ).toDateString();
}

// eslint-disable-next-line no-extend-native
Date.prototype.toUTCDateString = toUTCDateString;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarkly.clientId,
    context: {
      kind: 'user',
      key: 'anonymous-user-key',
      email: 'devnull@studentfinance.com',
    },
    options: {
      application: `sf-web ${window.SF_WEB_VERSION}`,
    },
  });

  render(
    <LDProvider>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: config.auth0.redirectUri,
          audience: config.authAudience,
          scope: 'profile email openid',
        }}
      >
        <Provider store={store()}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Auth0Provider>
    </LDProvider>,
    document.getElementById('root'),
  );
})();
