import { createActions, handleActions } from 'redux-actions';

export const defaultNotificationShape = {
  key: null,
  variant: null,
  message: null,
  title: null,
};

const defaultState = {
  queue: [],
};

const actions = createActions({
  ADD_NOTIFICATION: (variant, message, title) => ({ variant, message, title }),
  REMOVE_NOTIFICATION: (key) => ({ key }),
});

const { addNotification, removeNotification } = actions;

const reducer = handleActions(
  {
    [addNotification]: (state, { payload: { variant, message, title } }) => {
      // slice will create a new array, allowing us to not mutate original state
      const newQueue = state.queue.slice();
      newQueue.push({
        key: new Date().getTime(),
        variant,
        message,
        title,
      });
      return {
        queue: newQueue,
      };
    },
    [removeNotification]: (state, { payload: { key } }) => {
      // slice will create a new array, allowing us to not mutate original state
      const newQueue = state.queue.slice();
      const idx = newQueue.findIndex((i) => i.key === key);
      if (idx > -1) {
        newQueue.splice(idx, 1);
      }
      return {
        queue: newQueue,
      };
    },
  },
  defaultState,
);

export { actions, reducer };
