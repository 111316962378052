import { createActions, handleActions } from 'redux-actions';

const defaultState = {
  response: null,
};

const actions = createActions({
  LANGUAGE_OK: (response) => ({ response }),
});

const { languageOk } = actions;

const reducer = handleActions(
  {
    [languageOk]: (state, { payload: { response } }) => {
      return {
        ...state,
        response,
      };
    },
  },
  defaultState,
);

export { actions, reducer };
