import React from 'react';
import ContentLoader from 'react-content-loader';

const MenuLoader = () => (
  <ContentLoader
    height={1080}
    width={1920}
    speed={2}
    foregroundColor="#e3e3e3"
    backgroundColor="#ecebeb"
  >
    <rect height="1188.999969" width="500" y="3.453135" x="0.999996" />
    <rect height="10" width="916" y="62.453125" x="655.5" />
    <rect height="20.000001" width="690.999989" y="236.453124" x="655.5" />
    <rect height="236.000004" width="916" y="801.453125" x="655.5" />
    <rect height="236.000004" width="916" y="312.453125" x="655.5" />
    <rect height="20.000001" width="690.999989" y="202.453124" x="655.5" />
    <rect height="20.000001" width="690.999989" y="737.453124" x="655.5" />
    <rect height="20" width="155" y="135.453125" x="655.5" />
    <rect height="20" width="155" y="635.453125" x="655.5" />
    <rect height="20.000001" width="690.999989" y="701.453124" x="655.5" />

    <circle cx="30" cy="30" r="30" />
  </ContentLoader>
);

export default MenuLoader;
