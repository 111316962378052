import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '@sf/config';
import useActions from '@sf/shared/hooks/useActions';
import sleep from '@sf/shared/utils/sleep';
import useShallowEqualSelector from '../../shared/hooks/useShallowEqualSelector';
import { shapes } from '../redux';
import * as appApi from '../api';

const Notification = () => {
  // State
  const [open, setOpen] = useState(false);
  const [notification, setMessage] = useState(shapes.notifications);

  // Redux
  const queue = useShallowEqualSelector(
    (store) => store.app.notification.queue,
  );
  const appActions = useActions(appApi);

  const NoteHolder = styled.div`
    padding: 20px;
    position: fixed;
    top: 20px;
    font-size: 12px;
    max-width: 50%;
    right: 20px;
    border-radius: var(--border-radius-l);
    color: #fff;
    z-index: 1002;
    background-color: ${notification.variant === 'success'
      ? config.colors.appGreen
      : config.colors.appRed};

    @media (max-width: 768px) {
      top: 70px;
    }
  `;
  const handleClose = (key) => {
    setOpen(false);
    appActions.removeNotification(key);
  };

  useEffect(() => {
    (async () => {
      if (queue && queue.length > 0) {
        const queueItem = queue[0];
        await setMessage(queueItem);
        await setOpen(true);
        queue.map(async (note) => {
          await sleep(5000);
          handleClose(note.key);
        });
      }
    })();
  }, [queue]);

  if (open) {
    return (
      <NoteHolder data-testid="notificationHolder">
        {notification.message}
      </NoteHolder>
    );
  }
  return null;
};

export default Notification;
