/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import useActions from '@sf/shared/hooks/useActions';
import { useHistory } from 'react-router-dom';
import config from '@sf/config';
import typeMatchesRedirect from '@sf/shared/utils/typeMatchesRedirect';
import * as AppApi from '@sf/app/api';

import { actions } from '../redux';
import * as api from '../api';

const actionsOnMount = ['getCountries', 'getCities'];

/**
 * Container logic for the AuthWrapper presentation component.
 */
export default () => {
  // Global
  const token = localStorage.getItem('token');
  const authApiActions = useActions(api);
  const loginActions = useActions(actions.login);
  const { routes } = config;
  const appActions = useActions(AppApi);

  // Router
  const history = useHistory();

  // State
  const [ready, setReady] = useState(false);

  // Effect - "On mount"
  //   - Auth initialization/verification
  useEffect(() => {
    // we will run this effect only if there is no token in redux
    // this means there hasn't been a successful login flow, via the login screen
    // check if we have a token in localStorage
    if (token) {
      // if we do, let's see if the token is valid. we can call a backend API that returns the user information.
      (async () => {
        try {
          await loginActions.loginUserOk(null, token);
          const user = await authApiActions.initializeUser(token);
          const type = user.type === 'self' ? 'admin' : user.type;
          const allowed = await typeMatchesRedirect(
            type,
            history.location.pathname,
          );
          // make sure route matches user type
          if (!allowed) {
            console.log('%c role not allowed in this route', 'color: #f00');
            if (
              window.location.origin.includes('logout') ||
              window.location.origin.includes('register')
            )
              return;
            authApiActions.redirectToLogin(history, routes.base);
          } else {
            Promise.all(
              actionsOnMount.map((action) => appActions[action]()),
            ).then(() => setReady(true));
          }
        } catch (err) {
          // clear token and redirect to login
          authApiActions.redirectToLogin(history);
        }
      })();
    } else {
      // redirect to the login page with a redirect parameter (if applicable)
      authApiActions.redirectToLogin(history);
    }
  }, [token]);

  return {
    ready,
  };
};
