import { combineReducers } from 'redux';
import * as postRegisterEmployer from './postRegisterEmployer';
import * as postEmployerBasicData from './postEmployerBasicData';
import * as getSkills from './getSkills';
import * as postJobs from './postJobs';
import * as getJobs from './getJobs';

const reducer = combineReducers({
  postRegisterEmployer: postRegisterEmployer.reducer,
  postEmployerBasicData: postEmployerBasicData.reducer,
  getSkills: getSkills.reducer,
  postJobs: postJobs.reducer,
  getJobs: getJobs.reducer,
});

const actions = {
  postRegisterEmployer: postRegisterEmployer.actions,
  postEmployerBasicData: postEmployerBasicData.actions,
  getSkills: getSkills.actions,
  postJobs: postJobs.actions,
  getJobs: getJobs.actions,
};

export { actions, reducer };
