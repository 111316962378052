import Axios from 'axios';
import config from '@sf/config';
import { IMPERSONATE_KEY } from '@sf/shared/constants/keys';
/**
 * Abstraction of Axios calls to keep lines to a minimum when appicable in api file
 */
export default ({
  method,
  cType,
  url,
  req,
  ok,
  fail,
  payload,
  additionalHeaders = {},
}) => {
  const impersonateKey = sessionStorage.getItem(IMPERSONATE_KEY);
  return (dispatch) => {
    dispatch(req());
    return Axios({
      method,
      url,
      headers: {
        'X-Client-Version': window.SF_WEB_VERSION,
        ...(config.shouldUseAuth0 ? { 'x-auth-version': 'v2' } : {}),
        'Accept-Language': config.language,
        'Content-Type': cType || 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        ...(impersonateKey && {
          'x-user-impersonate': sessionStorage.getItem(IMPERSONATE_KEY),
        }),
        ...additionalHeaders,
      },
      data: payload || '',
      responseType:
        additionalHeaders.accept === 'application/octet-stream'
          ? 'blob'
          : 'json',
    })
      .then((response) => {
        const {
          status,
          headers: { authorization, ...remainingHeaders },
        } = response;
        if (Array.isArray(ok)) {
          ok.forEach((call) => {
            try {
              dispatch(call(response.data, { remainingHeaders, status }));
            } catch (err) {
              throw err;
            }
          });
        } else {
          dispatch(ok(response.data, { remainingHeaders, status }));
        }
        return response.data;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          const {
            status,
            headers: { authorization, ...remainingHeaders },
          } = err.response;
          dispatch(fail(err.response.data, { remainingHeaders, status }));
        } else {
          dispatch(fail(err.message, { headers: null, status: null }));
        }
        throw err;
      });
  };
};
