import { createActions, handleActions, combineActions } from 'redux-actions';

const defaultState = {
  commState: {
    isCommunicating: false,
    requestTimestamp: null,
    responseTimestamp: null,
  },
  response: null,
  error: false,
  token: null,
};

const actions = createActions({
  LOGIN_USER_REQ: () => ({}),
  LOGIN_USER_OK: (response, token) => ({ response, error: false, token }),
  LOGIN_USER_FAIL: (response) => ({ response, error: true, token: null }),
  LOGOUT_USER: () => ({ error: false, token: null }),
  LOGOUT_USER_DUE_TO_INVALID_TOKEN: () => ({}),
  LOGOUT_USER_DUE_TO_WRONG_COBRAND: () => ({}),
  REDIRECT_TO_LOGIN: () => ({}),
});

const {
  loginUserReq,
  loginUserOk,
  loginUserFail,
  logoutUser,
  logoutUserDueToInvalidToken,
  redirectToLogin,
} = actions;

const reducer = handleActions(
  {
    [loginUserReq]: (state) => ({
      ...state,
      commState: {
        isCommunicating: true,
        requestTimestamp: Date.now(),
        responseTimestamp: null,
      },
    }),
    [combineActions(loginUserOk, loginUserFail)]: (
      state,
      { payload: { response, error, token } },
    ) => {
      return {
        ...state,
        commState: {
          ...state.commState,
          isCommunicating: false,
          responseTimestamp: Date.now(),
        },
        response,
        error,
        token,
      };
    },
    [combineActions(logoutUser, logoutUserDueToInvalidToken, redirectToLogin)]:
      () => ({
        ...defaultState,
      }),
  },
  defaultState,
);

export { actions, reducer };
