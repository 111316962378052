import { combineReducers } from 'redux';
import * as postBankAggregationData from './postBankAggregationData';

const reducer = combineReducers({
  postBankAggregationData: postBankAggregationData.reducer,
});

const actions = {
  postBankAggregationData: postBankAggregationData.actions,
};

export { actions, reducer };
