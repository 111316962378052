/* eslint-disable import/prefer-default-export */
import axiosCall from '@sf/shared/utils/axiosCall';
import { actions } from './redux';
import config from '../config';

export const getDocuments = (id) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/documents/${id}`,
        req: actions.getDocuments.getDocumentsReq,
        ok: actions.getDocuments.getDocumentsOk,
        fail: actions.getDocuments.getDocumentsFail,
      }),
    );
  };
};

export const getDocumentContent = (id) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/documents/${id}/content`,
        req: actions.getDocumentContent.getDocumentContentReq,
        ok: actions.getDocumentContent.getDocumentContentOk,
        fail: actions.getDocumentContent.getDocumentContentFail,
      }),
    );
  };
};

export const getScoreItems = () => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/score-items`,
        req: actions.getScoreItems.getScoreItemsReq,
        ok: actions.getScoreItems.getScoreItemsOk,
        fail: actions.getScoreItems.getScoreItemsFail,
      }),
    );
  };
};

export const getScoreLabels = () => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/score-labels`,
        req: actions.getScoreLabels.getScoreLabelsReq,
        ok: actions.getScoreLabels.getScoreLabelsOk,
        fail: actions.getScoreLabels.getScoreLabelsFail,
      }),
    );
  };
};

export const postScoreItemReviews = (data) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/score-item-reviews`,
        req: actions.postScoreItemReviews.postScoreItemReviewsReq,
        ok: actions.postScoreItemReviews.postScoreItemReviewsOk,
        fail: actions.postScoreItemReviews.postScoreItemReviewsFail,
        payload: data,
      }),
    );
  };
};

export const getReviews = (id) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/score-item-reviews?applicationId=${id}`,
        req: actions.getReviews.getReviewsReq,
        ok: actions.getReviews.getReviewsOk,
        fail: actions.getReviews.getReviewsFail,
      }),
    );
  };
};

export const getAplicationStatuses = () => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/application-statuses`,
        req: actions.getAplicationStatuses.getAplicationStatusesReq,
        ok: actions.getAplicationStatuses.getAplicationStatusesOk,
        fail: actions.getAplicationStatuses.getAplicationStatusesFail,
      }),
    );
  };
};

export const getClosedApplicationStatuses = () => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/application-statuses?isClosedType=true`,
        req: actions.getClosedApplicationStatuses
          .getClosedApplicationStatusesReq,
        ok: actions.getClosedApplicationStatuses.getClosedApplicationStatusesOk,
        fail: actions.getClosedApplicationStatuses
          .getClosedApplicationStatusesFail,
      }),
    );
  };
};

export const getSchoolApplicationReview = (id) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/application-feedbacks/${id}`,
        req: actions.getSchoolApplicationReview.getSchoolApplicationReviewReq,
        ok: actions.getSchoolApplicationReview.getSchoolApplicationReviewOk,
        fail: actions.getSchoolApplicationReview.getSchoolApplicationReviewFail,
      }),
    );
  };
};

export const getAllSchools = () => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools?sort=name[asc]`,
        req: actions.getAllSchools.getAllSchoolsReq,
        ok: actions.getAllSchools.getAllSchoolsOk,
        fail: actions.getAllSchools.getAllSchoolsFail,
      }),
    );
  };
};

export const getSchool = (schoolId) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools/${schoolId}`,
        req: actions.getSchool.getSchoolReq,
        ok: actions.getSchool.getSchoolOk,
        fail: actions.getSchool.getSchoolFail,
      }),
    );
  };
};

export const patchSchool = (id, school) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'patch',
        url: `${config.apiUrl}/schools/${id}`,
        req: actions.getSchool.getSchoolReq,
        ok: actions.getSchool.getSchoolOk,
        fail: actions.getSchool.getSchoolFail,
        payload: school,
      }),
    );
  };
};

// export const getContracts = (schoolId) => {
//   return (dispatch) => {
//     return dispatch(
//       axiosCall({
//         method: 'get',
//         url: `${config.apiUrl}/contracts?schoolId=${schoolId}`,
//         req: actions.getContracts.getContractsReq,
//         ok: actions.getContracts.getContractsOk,
//         fail: actions.getContracts.getContractsFail,
//       }),
//
//     );
//   };
// };

// export const postContract = (contract) => {
//   return (dispatch) => {
//     return dispatch(
//       axiosCall({
//         method: 'post',
//         url: `${config.apiUrl}/contracts`,
//         req: actions.postContracts.postContractsReq,
//         ok: actions.postContracts.postContractsOk,
//         fail: actions.postContracts.postContractsFail,
//         payload: contract,
//       }),
//     );
//   };
// };

// export const patchContract = (contract) => {
//   return (dispatch) => {
//     return dispatch(
//       axiosCall({
//         method: 'patch',
//         url: `${config.apiUrl}/contracts/${contract.id}`,
//         req: actions.patchContracts.patchContractsReq,
//         ok: actions.patchContracts.patchContractsOk,
//         fail: actions.patchContracts.patchContractsFail,
//         payload: contract,
//       }),
//     );
//   };
// };

export const putStudentBankData = (id, data) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/students/${id}`,
        req: actions.putStudentBankData.putStudentBankDataReq,
        ok: actions.putStudentBankData.putStudentBankDataOk,
        fail: actions.putStudentBankData.putStudentBankDataFail,
        payload: data,
      }),
    );
  };
};

export function postCourseCohort(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/course-cohorts`,
        req: actions.postCourseCohort.postCourseCohortReq,
        ok: actions.postCourseCohort.postCourseCohortOk,
        fail: actions.postCourseCohort.postCourseCohortFail,
        payload: data,
      }),
    );
  };
}

export function patchCourseCohort(data) {
  const { cohortId, startDate, endDate, cityId } = data;
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'patch',
        url: `${config.apiUrl}/course-cohorts/${cohortId}`,
        req: actions.patchCourseCohort.patchCourseCohortReq,
        ok: actions.patchCourseCohort.patchCourseCohortOk,
        fail: actions.patchCourseCohort.patchCourseCohortFail,
        payload: {
          cityId,
          startDate,
          endDate,
        },
      }),
    );
  };
}

export const patchApplicationData = (appId, data) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'patch',
        url: `${config.apiUrl}/applications/${appId}`,
        req: actions.patchApplicationData.patchApplicationDataReq,
        ok: actions.patchApplicationData.patchApplicationDataOk,
        fail: actions.patchApplicationData.patchApplicationDataFail,
        payload: data,
      }),
    );
  };
};

export const getIncomes = (studentId) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/incomes?studentId=${studentId}`,
        req: actions.getIncomes.getIncomesReq,
        ok: actions.getIncomes.getIncomesOk,
        fail: actions.getIncomes.getIncomesFail,
      }),
    );
  };
};

export const postIncomes = (income) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/incomes`,
        req: actions.postIncomes.postIncomesReq,
        ok: actions.postIncomes.postIncomesOk,
        fail: actions.postIncomes.postIncomesFail,
        payload: income,
      }),
    );
  };
};

export const putIncomes = (id, income) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/incomes/${id}`,
        req: actions.putIncomes.putIncomesReq,
        ok: actions.putIncomes.putIncomesOk,
        fail: actions.putIncomes.putIncomesFail,
        payload: income,
      }),
    );
  };
};

export const getRepayments = (studentId) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/repayments?studentId=${studentId}`,
        req: actions.getRepayments.getRepaymentsReq,
        ok: actions.getRepayments.getRepaymentsOk,
        fail: actions.getRepayments.getRepaymentsFail,
      }),
    );
  };
};

export const postRepayments = (income) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/repayments`,
        req: actions.postRepayments.postRepaymentsReq,
        ok: actions.postRepayments.postRepaymentsOk,
        fail: actions.postRepayments.postRepaymentsFail,
        payload: income,
      }),
    );
  };
};

export const putRepayments = (id, income) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/repayments/${id}`,
        req: actions.putRepayments.putRepaymentsReq,
        ok: actions.putRepayments.putRepaymentsOk,
        fail: actions.putRepayments.putRepaymentsFail,
        payload: income,
      }),
    );
  };
};

export const getApplicationById = (id) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/applications/${id}`,
        req: actions.getApplicationById.getApplicationByIdReq,
        ok: actions.getApplicationById.getApplicationByIdOk,
        fail: actions.getApplicationById.getApplicationByIdFail,
      }),
    );
  };
};

export const putKycProfile = (userId) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/kyc/kyc-profiles`,
        req: actions.putKycProfile.putKycProfileReq,
        ok: actions.putKycProfile.putKycProfileOk,
        fail: actions.putKycProfile.putKycProfileFail,
        payload: { userId },
      }),
    );
  };
};

export const patchUser = (id, data) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'patch',
        url: `${config.apiUrl}/users/${id}`,
        req: actions.patchUsers.patchUsersReq,
        ok: actions.patchUsers.patchUsersOk,
        fail: actions.patchUsers.patchUsersFail,
        payload: data,
      }),
    );
  };
};

export const putApplicationCourseData = (id, data) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        url: `${config.apiUrl}/applications/${id}`,
        req: actions.putApplicationCourseData.putApplicationCourseDataReq,
        ok: actions.putApplicationCourseData.putApplicationCourseDataOk,
        fail: actions.putApplicationCourseData.putApplicationCourseDataFail,
        payload: data,
      }),
    );
  };
};

export const postAdminQueue = (queue, payload) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/admin/queue`,
        req: actions.postAdminQueue.postAdminQueueReq,
        ok: actions.postAdminQueue.postAdminQueueOk,
        fail: actions.postAdminQueue.postAdminQueueFail,
        payload: {
          queue,
          payload,
        },
      }),
    );
  };
};

export const getStudentIncomeDetails = (studentId) => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/students/${studentId}/income-details`,
        req: actions.getStudentIncomeDetails.getStudentIncomeDetailsReq,
        ok: actions.getStudentIncomeDetails.getStudentIncomeDetailsOk,
        fail: actions.getStudentIncomeDetails.getStudentIncomeDetailsFail,
      }),
    );
  };
};
