import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from './rootReducer';

const configureStore = (initialState) => {
  let middleware;
  let composeEnhancers;

  if (process.env.TARGET_ENV === 'local') {
    const logger = createLogger({ collapsed: true, duration: true });
    middleware = applyMiddleware(thunk, logger);
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
  } else {
    middleware = applyMiddleware(thunk);
    composeEnhancers = compose;
  }

  return createStore(rootReducer, initialState, composeEnhancers(middleware));
};
export default configureStore;
