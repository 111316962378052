import React from 'react';
import Error from '../../shared/styledComponents/Error';

const KEY_PREFIX = 'has-tried-to-reload-window';

function tryReloadingTheWindowForError(errorName) {
  const key = KEY_PREFIX + errorName;

  const hasTriedToReloadTheWindowForThisError =
    window.sessionStorage.getItem(key) === 'true';

  if (!hasTriedToReloadTheWindowForThisError) {
    window.sessionStorage.setItem(key, 'true');
    window.location.reload();
    return true;
  }

  window.sessionStorage.setItem(key, 'false');
  return false;
}

// eslint-disable-next-line react/prefer-stateless-function
export default class ErrorBoundaryMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error) {
    const { reloadOnErrorNameMatching } = this.props;

    if (
      reloadOnErrorNameMatching &&
      reloadOnErrorNameMatching.test(error.name)
    ) {
      const willReload = tryReloadingTheWindowForError(error.name);
      if (willReload) {
        return;
      }
    }

    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children, onError } = this.props;

    if (!error) {
      return children;
    }

    if (typeof onError === 'function') {
      onError(error);
    }

    return (
      <Error
        title="Oops, something went wrong."
        description="We are sorry, but we were unable to fulfill your request."
      />
    );
  }
}
