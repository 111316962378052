import { combineReducers } from 'redux';
import * as postEligibilityData from './postEligibilityData';
import * as postApplicationData from './postApplicationData';
import * as postBackgroundData from './postBackgroundData';
import * as postMotivationData from './postMotivationData';
import * as postRegisterStudent from './postRegisterStudent';
import * as postFinBackgroundData from './postFinBackgroundData';
import * as postOtherData from './postOtherData';
import * as getAllActiveSchools from './getAllActiveSchools';
import * as getMotivationQuestions from './getMotivationQuestions';
import * as getStudent from './getStudent';
import * as postPersonalData from './postPersonalData';
import * as postExpensesData from './postExpensesData';
import * as postEducationRecord from './postEducationRecord';
import * as putEducationRecord from './putEducationRecord';
import * as deleteEducationRecord from './deleteEducationRecord';
import * as getEducationRecords from './getEducationRecords';
import * as updateStudent from './updateStudent';
import * as postJobsAvailabilityData from './postJobsAvailabilityData';
import * as getBankAccounts from './getBankAccounts';
import * as getOnfidoApplicant from './getOnfidoApplicant';
import * as createOnfidoApplicant from './createOnfidoApplicant';
import * as createOnfidoSdkToken from './createOnfidoSdkToken';
import * as updateOnfidoApplicant from './updateOnfidoApplicant';
import * as createOnfidoCheck from './createOnfidoCheck';
import * as getSchoolBySlug from './getSchoolBySlug';
import * as patchStudents from './patchStudents';
import * as getAllActiveSchoolsByCountryCode from './getAllActiveSchoolsByCountryCode';

const reducer = combineReducers({
  postEligibilityData: postEligibilityData.reducer,
  postApplicationData: postApplicationData.reducer,
  postBackgroundData: postBackgroundData.reducer,
  postMotivationData: postMotivationData.reducer,
  postRegisterStudent: postRegisterStudent.reducer,
  postFinBackgroundData: postFinBackgroundData.reducer,
  postOtherData: postOtherData.reducer,
  getAllActiveSchools: getAllActiveSchools.reducer,
  getAllActiveSchoolsByCountryCode: getAllActiveSchoolsByCountryCode.reducer,
  getMotivationQuestions: getMotivationQuestions.reducer,
  getStudent: getStudent.reducer,
  postPersonalData: postPersonalData.reducer,
  postExpensesData: postExpensesData.reducer,
  postEducationRecord: postEducationRecord.reducer,
  putEducationRecord: putEducationRecord.reducer,
  deleteEducationRecord: deleteEducationRecord.reducer,
  getEducationRecords: getEducationRecords.reducer,
  updateStudent: updateStudent.reducer,
  postJobsAvailabilityData: postJobsAvailabilityData.reducer,
  getBankAccounts: getBankAccounts.reducer,
  getOnfidoApplicant: getOnfidoApplicant.reducer,
  createOnfidoApplicant: createOnfidoApplicant.reducer,
  createOnfidoSdkToken: createOnfidoSdkToken.reducer,
  updateOnfidoApplicant: updateOnfidoApplicant.reducer,
  createOnfidoCheck: createOnfidoCheck.reducer,
  getSchoolBySlug: getSchoolBySlug.reducer,
  patchStudents: patchStudents.reducer,
});

const actions = {
  postEligibilityData: postEligibilityData.actions,
  postApplicationData: postApplicationData.actions,
  postBackgroundData: postBackgroundData.actions,
  postMotivationData: postMotivationData.actions,
  postRegisterStudent: postRegisterStudent.actions,
  postFinBackgroundData: postFinBackgroundData.actions,
  postOtherData: postOtherData.actions,
  getAllActiveSchools: getAllActiveSchools.actions,
  getAllActiveSchoolsByCountryCode: getAllActiveSchoolsByCountryCode.actions,
  getMotivationQuestions: getMotivationQuestions.actions,
  getStudent: getStudent.actions,
  postPersonalData: postPersonalData.actions,
  postExpensesData: postExpensesData.actions,
  postEducationRecord: postEducationRecord.actions,
  putEducationRecord: putEducationRecord.actions,
  deleteEducationRecord: deleteEducationRecord.actions,
  getEducationRecords: getEducationRecords.actions,
  updateStudent: updateStudent.actions,
  postJobsAvailabilityData: postJobsAvailabilityData.actions,
  getBankAccounts: getBankAccounts.actions,
  getOnfidoApplicant: getOnfidoApplicant.actions,
  createOnfidoApplicant: createOnfidoApplicant.actions,
  createOnfidoSdkToken: createOnfidoSdkToken.actions,
  updateOnfidoApplicant: updateOnfidoApplicant.actions,
  createOnfidoCheck: createOnfidoCheck.actions,
  getSchoolBySlug: getSchoolBySlug.actions,
  patchStudents: patchStudents.actions,
};

export { actions, reducer };
