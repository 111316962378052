import { combineReducers } from 'redux';

import { reducer as appReducer } from './app';
import { reducer as authReducer } from './auth';
import { reducer as studentReducer } from './student';
import { reducer as schoolReducer } from './school';
import { reducer as employerReducer } from './employer';
import { reducer as adminReducer } from './admin';
import { reducer as tinkReducer } from './tink';

const combinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  student: studentReducer,
  school: schoolReducer,
  employer: employerReducer,
  admin: adminReducer,
  tink: tinkReducer,
});

// Wrap combinedReduer into a rootReducer. This will enable us to clear all the store when certain actions are triggered (ex: logout),
// with the exception of the app reducer. This will allow us to reuse user-agnostic information as well as allow us to
// redirect to login with a notification

// const invalidateStateActions = [
// authActions.login.logoutUser().type,
// authActions.login.logoutUserDueToInvalidToken().type,
// authActions.login.logoutUserDueToWrongCobrand().type,
// authActions.login.redirectToLogin().type
// ];

const rootReducer = (state, action) => combinedReducer(state, action);

export default rootReducer;
