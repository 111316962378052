/* eslint-disable no-nested-ternary */
import React from 'react';
import config from '@sf/config';
import styled from 'styled-components';
import useButtons from '../hooks/useButtons';

const { colors } = config;

export const ButtonPrimary = styled(
  ({
    color,
    background,
    hoverColor,
    hoverBackground,
    focusColor,
    disabledColor,
    small,
    center,
    theme,
    loading,
    ...props
    // eslint-disable-next-line react/button-has-type
  }) => <button {...props} />,
)`
  ${({
    color = colors.white,
    background = colors.appBlue,
    hoverColor = background,
    hoverBackground = colors.appLightBlue,
    focusColor = colors.appLightBlue,
    disabledColor = colors.gray,
    disabled,
    small,
    center,
    theme,
    loading,
  }) => `
  color: ${color};
  font-family: ${theme.fontFamily};
  background: ${disabled ? colors.gray : background};
  min-width: ${small ? '100px' : '130px'};
  height: ${small ? '38px' : '48px'};
  font-size: ${small ? '.8rem' : '1rem'};
  display: block;
  margin: ${center ? 'auto' : 'initial'};
  border-radius: 30px;
  border: none;
  cursor: ${loading ? 'wait' : disabled ? 'not-allowed' : 'pointer'};
  padding: 0 30px;

  :focus {
    outline: none;
    box-shadow: 0 0 0 8px ${focusColor};
  }

  :hover {
    color: ${disabled ? color : hoverColor};
    background: ${disabled ? disabledColor : hoverBackground};
  }
`}
`;

export const ButtonSecondary = styled.button`
  color: ${(props) => (props.color ? props.color : colors.appPurple)};
  background-color: ${(props) => (props.disabled ? colors.gray : colors.white)};
  min-width: ${(props) => (props.small ? '100px' : '130px')};
  height: ${(props) => (props.small ? '38px' : '48px')};
  font-size: ${(props) => (props.small ? '.8rem' : '1rem')};
  border-radius: 30px;
  padding: 0px 30px;
  text-transform: capitalize;
  display: block;
  font-family: ${(props) => props.theme.fontFamily};
  border: 1px solid ${(props) => (props.color ? props.color : colors.appPurple)};
  margin: ${(props) => (props.center ? 'auto' : 'initial')};
  cursor: ${(props) =>
    props.loading ? 'wait' : props.disabled ? 'not-allowed' : 'pointer'};
  :focus {
    outline: none;
    box-shadow: 0 0 0 8px ${colors.appLightBlue};
  }
  :hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : colors.appBlue)};
    border-color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.appLightBlue};
  }
`;

export const ButtonSecondaryGreen = styled.button`
  color: ${colors.appGreen};
  background-color: ${(props) => (props.disabled ? colors.gray : colors.white)};
  min-width: ${(props) => (props.small ? '100px' : '130px')};
  height: ${(props) => (props.small ? '38px' : '48px')};
  font-size: ${(props) => (props.small ? '.8rem' : '1rem')};
  border-radius: 30px;
  text-transform: capitalize;
  padding: 0 20px;
  display: block;
  font-family: ${(props) => props.theme.fontFamily};
  border: 1px solid ${colors.appGreen};
  margin: ${(props) => (props.center ? 'auto' : 'initial')};
  cursor: pointer;
  :focus {
    outline: none;
    background-color: ${colors.appLightGreen};
  }
  :hover {
    color: ${colors.white};
    border-color: ${colors.appGreen};
    background-color: ${colors.appGreen};
  }
`;

const ButtonSquareComponent = styled.button`
  color: ${(props) => (props.active ? colors.appBlue : colors.appPurple)};
  background-color: ${(props) =>
    props.disabled
      ? colors.gray
      : props.active
      ? colors.appLightBlue
      : colors.white};
  height: 48px;
  font-size: 1rem;
  padding: 0 16px;
  border-radius: var(--border-radius-m);
  text-transform: ${(props) => (props.noCapitalize ? 'none' : 'capitalize')};
  display: flex;
  min-width: 60px;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.fontFamily};
  border: 1px solid
    ${(props) => {
      if (props.hasError) return colors.appRed;
      return props.active ? colors.appBlue : colors.appPurple;
    }};
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  :focus {
    outline: none;
    ${(props) =>
      props.active
        ? `box-shadow: 0 0 0 2px ${colors.appBlue}`
        : `box-shadow: 0 0 0 4px ${colors.secondaryPurple}`}
  }
  :hover {
    box-shadow: ${(props) => (props.disabled ? '0' : '0 0 0 1px currentColor')};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ButtonSquare = (props) => (
  <ButtonSquareComponent type="button" {...props} />
);

export const OkButton = styled.div`
  color: ${colors.textGray};
  background-color: ${colors.white};
  min-width: ${(props) => (props.small ? '50px' : '100px')};
  height: ${(props) => (props.small ? '28px' : '48px')};
  font-size: ${(props) => (props.small ? '.8rem' : '1rem')};
  border-radius: 25px;
  text-align: center;
  line-height: 28px;
  text-transform: capitalize;
  display: block;
  font-family: ${(props) => props.theme.fontFamily};
  border: 1px solid ${colors.gray};
  margin: 0px 20px 0px 30px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    color: ${colors.white};
    background-color: ${colors.appGreen};
  }
`;

export const NeedsClarificationButton = styled.div`
  color: ${colors.textGray};
  background-color: ${colors.white};
  min-width: ${(props) => (props.small ? '50px' : '100px')};
  height: ${(props) => (props.small ? '28px' : '48px')};
  font-size: ${(props) => (props.small ? '.8rem' : '1rem')};
  border-radius: 25px;
  text-transform: capitalize;
  display: block;
  line-height: 28px;
  padding: 0 20px;
  font-family: ${(props) => props.theme.fontFamily};
  border: 1px solid ${colors.gray};
  margin: ${(props) => (props.center ? 'auto' : 'initial')};
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    color: ${colors.white};
    background-color: ${colors.appLightRed};
  }
`;

const ContextMenuWrapper = styled.div`
  position: absolute;
  top: 30px;
  cursor: default;
`;

const ContextMenu = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: var(--border-radius-l);
  width: 205px;
  text-align: left;
  box-shadow: 0 4px 20px ${colors.gray};
  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      margin: 20px 30px;

      & > span {
        color: ${colors.textGray};
        margin: 0;
        padding: 0;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
`;

const ButtonNoBordersWithIconComponent = ({
  icon,
  iconPosition = 'left',
  children,
  className,
  style,
  onClick,
  contextMenu,
  contextMenuAlignment = 'left',
}) => {
  const { contextMenuOpen, toggleContextMenu } = useButtons();
  return (
    <button
      type="button"
      style={{
        backgroundColor: 'transparent',
        border: 0,
        margin: 0,
        padding: 0,
        position: 'relative',
        ...style,
      }}
      className={className}
      onClick={(e) => {
        if (contextMenu) toggleContextMenu();
        else onClick(e);
      }}
    >
      {!!icon && iconPosition === 'left' && <div className="icon">{icon}</div>}
      <div className="label">{children}</div>
      {!!icon && iconPosition === 'right' && <div className="icon">{icon}</div>}
      {!!contextMenu && contextMenuOpen && (
        <ContextMenuWrapper
          style={
            contextMenuAlignment === 'left' ? { left: '0' } : { right: '0' }
          }
          onMouseLeave={toggleContextMenu}
        >
          <ContextMenu>
            <ul>
              {contextMenu.map((item, index) => (
                <li key={index}>
                  <span
                    role="button"
                    tabIndex={0 - index}
                    onClick={item.action}
                    onKeyUp={item.action}
                  >
                    {item.label}
                  </span>
                </li>
              ))}
            </ul>
          </ContextMenu>
        </ContextMenuWrapper>
      )}
    </button>
  );
};

export const ButtonNoBordersWithIcon = styled(ButtonNoBordersWithIconComponent)`
  margin: 5px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) =>
    props.hasError ? colors.appRed : props.color || colors.black};
  font-size: 0.9rem;
  & > .label {
    margin: 0 5px;
  }
  svg {
    fill: ${(props) => props.color || colors.black};
  }

  :focus {
    box-shadow: 0 0 0 4px ${colors.appLightBlue};
    outline: 0;
  }

  > .icon {
    display: flex;
  }
`;

export const ButtonSuccess = styled((props) => (
  <ButtonPrimary
    color={colors.white}
    background={colors['green-500']}
    hoverColor={colors.white}
    hoverBackground={colors['green-800']}
    focusColor={colors['green-100']}
    disabledColor={colors['green-100']}
    {...props}
  />
))``;

export const TextButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  display: flex;
  color: ${colors.appBlue};
  align-items: center;
`;
