export default (len) => {
  const language = len.split('-')[0];
  switch (language) {
    case 'pt':
    case 'es':
    case 'en':
      return language;
    default:
      return 'en';
  }
};
