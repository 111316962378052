import { combineReducers } from 'redux';
import * as postRegisterSchool from './postRegisterSchool';
import * as getSkills from './getSkills';
import * as postSchoolBasicData from './postSchoolBasicData';
import * as postSchoolPocData from './postSchoolPocData';
import * as postCourses from './postCourses';
import * as getCourses from './getCourses';
import * as deleteCourse from './deleteCourse';
import * as getReviewOptions from './getReviewOptions';
import * as postReviewOptions from './postReviewOptions';
import * as getSchoolEmbededAirtableIds from './getSchoolEmbededAirtableIds';
import * as getIsaTerms from './getIsaTerms';
import * as getCohortIsaTerms from './getCohortIsaTerms';
import * as getSchoolIsaTerms from './getSchoolIsaTerms';

const reducer = combineReducers({
  postRegisterSchool: postRegisterSchool.reducer,
  postSchoolBasicData: postSchoolBasicData.reducer,
  postSchoolPocData: postSchoolPocData.reducer,
  getSkills: getSkills.reducer,
  postCourses: postCourses.reducer,
  getCourses: getCourses.reducer,
  deleteCourse: deleteCourse.reducer,
  getReviewOptions: getReviewOptions.reducer,
  postReviewOptions: postReviewOptions.reducer,
  getSchoolEmbededAirtableIds: getSchoolEmbededAirtableIds.reducer,
  getIsaTerms: getIsaTerms.reducer,
  getCohortIsaTerms: getCohortIsaTerms.reducer,
  getSchoolIsaTerms: getSchoolIsaTerms.reducer,
});

const actions = {
  postRegisterSchool: postRegisterSchool.actions,
  postSchoolBasicData: postSchoolBasicData.actions,
  postSchoolPocData: postSchoolPocData.actions,
  getSkills: getSkills.actions,
  postCourses: postCourses.actions,
  getCourses: getCourses.actions,
  deleteCourse: deleteCourse.actions,
  getReviewOptions: getReviewOptions.actions,
  postReviewOptions: postReviewOptions.actions,
  getSchoolEmbededAirtableIds: getSchoolEmbededAirtableIds.actions,
  getIsaTerms: getIsaTerms.actions,
  getCohortIsaTerms: getCohortIsaTerms.actions,
  getSchoolIsaTerms: getSchoolIsaTerms.actions,
};

export { actions, reducer };
