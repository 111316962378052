import React, { Suspense } from 'react';
import MenuLoader from '../loaders/MenuLoader';

/**
 * Wrapper component which displays a MenuLoader component while the actual component is "getting ready".
 */
const SuspenseWrapper = ({ isReadyToRender = true, fallback, children }) => {
  // fallback is expecting a Component! that's why we are "invoking" MenuLoader
  return isReadyToRender ? (
    <Suspense fallback={fallback || MenuLoader()}>{children}</Suspense>
  ) : (
    ''
  );
};

export default SuspenseWrapper;
