import axiosCall from '@sf/shared/utils/axiosCall';
import { actions } from './redux';
import config from '../config';

export function postRegisterSchool(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/schools?sort=name[asc]`,
        req: actions.postRegisterSchool.postRegisterSchoolReq,
        ok: actions.postRegisterSchool.postRegisterSchoolOk,
        fail: actions.postRegisterSchool.postRegisterSchoolFail,
        payload: data,
      }),
    );
  };
}

export function postSchoolBasicData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/school-onboard/info`,
        req: actions.postSchoolBasicData.postSchoolBasicDataReq,
        ok: actions.postSchoolBasicData.postSchoolBasicDataOk,
        fail: actions.postSchoolBasicData.postSchoolBasicDataFail,
        payload: data,
      }),
    );
  };
}

export function postSchoolPocData(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/school-onboard/contact`,
        req: actions.postSchoolPocData.postSchoolPocDataReq,
        ok: actions.postSchoolPocData.postSchoolPocDataOk,
        fail: actions.postSchoolPocData.postSchoolPocDataFail,
        payload: data,
      }),
    );
  };
}

export function postCourses(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/courses`,
        req: actions.postCourses.postCoursesReq,
        ok: actions.postCourses.postCoursesOk,
        fail: actions.postCourses.postCoursesFail,
        payload: data,
      }),
    );
  };
}

export function getSkills() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/skills`,
        req: actions.getSkills.getSkillsReq,
        ok: actions.getSkills.getSkillsOk,
        fail: actions.getSkills.getSkillsFail,
      }),
    );
  };
}

export function getCourses(schoolId) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/courses?schoolId=${schoolId}`,
        req: actions.getCourses.getCoursesReq,
        ok: actions.getCourses.getCoursesOk,
        fail: actions.getCourses.getCoursesFail,
      }),
    );
  };
}

export function getReviewOptions() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/application-feedback-fields`,
        req: actions.getReviewOptions.getReviewOptionsReq,
        ok: actions.getReviewOptions.getReviewOptionsOk,
        fail: actions.getReviewOptions.getReviewOptionsFail,
      }),
    );
  };
}

export function postReviewOptions(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/application-feedbacks`,
        req: actions.postReviewOptions.postReviewOptionsReq,
        ok: actions.postReviewOptions.postReviewOptionsOk,
        fail: actions.postReviewOptions.postReviewOptionsFail,
        payload: data,
      }),
    );
  };
}

export function deleteCourse(courseId) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'delete',
        url: `${config.apiUrl}/courses/${courseId}`,
        req: actions.deleteCourse.deleteCourseReq,
        ok: actions.deleteCourse.deleteCourseOk,
        fail: actions.deleteCourse.deleteCourseFail,
      }),
    );
  };
}

export function getSchoolEmbededAirtableIds(id) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools/${id}/embeded-airtable-ids`,
        req: actions.getSchoolEmbededAirtableIds.getSchoolEmbededAirtableIdsReq,
        ok: actions.getSchoolEmbededAirtableIds.getSchoolEmbededAirtableIdsOk,
        fail: actions.getSchoolEmbededAirtableIds
          .getSchoolEmbededAirtableIdsFail,
      }),
    );
  };
}

export function getIsaTerms(applicationId) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/applications/${applicationId}/finance-terms`,
        req: actions.getIsaTerms.getIsaTermsReq,
        ok: actions.getIsaTerms.getIsaTermsOk,
        fail: actions.getIsaTerms.getIsaTermsFail,
      }),
    );
  };
}

export function getCohortIsaTerms(cohortId) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/course-cohorts/${cohortId}/finance-terms`,
        req: actions.getCohortIsaTerms.getCohortIsaTermsReq,
        ok: actions.getCohortIsaTerms.getCohortIsaTermsOk,
        fail: actions.getCohortIsaTerms.getCohortIsaTermsFail,
      }),
    );
  };
}

export function getSchoolIsaTerms(schoolId) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/schools/${schoolId}/finance-terms`,
        req: actions.getSchoolIsaTerms.getSchoolIsaTermsReq,
        ok: actions.getSchoolIsaTerms.getSchoolIsaTermsOk,
        fail: actions.getSchoolIsaTerms.getSchoolIsaTermsFail,
      }),
    );
  };
}
