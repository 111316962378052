import axiosCall from '@sf/shared/utils/axiosCall';
import { actions } from './redux';
import config from '../config';

const languages = require('@sf/shared/utils/languages.json');

export function getEducationFields() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/education-fields`,
        req: actions.getEducationFields.getEducationFieldsReq,
        ok: actions.getEducationFields.getEducationFieldsOk,
        fail: actions.getEducationFields.getEducationFieldsFail,
      }),
    );
  };
}

export function getEmploymentStatus() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/employment-statuses`,
        req: actions.getEmploymentStatus.getEmploymentStatusReq,
        ok: actions.getEmploymentStatus.getEmploymentStatusOk,
        fail: actions.getEmploymentStatus.getEmploymentStatusFail,
      }),
    );
  };
}

export function getCities(country) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/cities${country ? `?country=${country}` : ''}`,
        req: actions.getCities.getCitiesReq,
        ok: actions.getCities.getCitiesOk,
        fail: actions.getCities.getCitiesFail,
      }),
    );
  };
}

export function getCountries() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/countries`,
        req: actions.getCountries.getCountriesReq,
        ok: actions.getCountries.getCountriesOk,
        fail: actions.getCountries.getCountriesFail,
      }),
    );
  };
}

export function getAllStudents() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/students`,
        req: actions.getAllStudents.getAllStudentsReq,
        ok: actions.getAllStudents.getAllStudentsOk,
        fail: actions.getAllStudents.getAllStudentsFail,
      }),
    );
  };
}

export function getApplications(query) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/applications${query || ''}`,
        req: actions.getApplications.getApplicationsReq,
        ok: actions.getApplications.getApplicationsOk,
        fail: actions.getApplications.getApplicationsFail,
      }),
    );
  };
}

export function getApplicationReviews({ applicationId }) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/application-reviews?applicationId=${applicationId}`,
        req: actions.getApplicationReviews.getApplicationReviewsReq,
        ok: actions.getApplicationReviews.getApplicationReviewsOk,
        fail: actions.getApplicationReviews.getApplicationReviewsFail,
      }),
    );
  };
}

export function getStudentDataById(id) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/students/${id}`,
        req: actions.getStudentDataById.getStudentDataByIdReq,
        ok: actions.getStudentDataById.getStudentDataByIdOk,
        fail: actions.getStudentDataById.getStudentDataByIdFail,
      }),
    );
  };
}

export function getWorkPermitOptions() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/work-permit-options`,
        req: actions.getWorkPermitOptions.getWorkPermitOptionsReq,
        ok: actions.getWorkPermitOptions.getWorkPermitOptionsOk,
        fail: actions.getWorkPermitOptions.getWorkPermitOptionsFail,
      }),
    );
  };
}

export function getIndustries() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/industries`,
        req: actions.getIndustries.getIndustriesReq,
        ok: actions.getIndustries.getIndustriesOk,
        fail: actions.getIndustries.getIndustriesFail,
      }),
    );
  };
}

export function getEducationTypes() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/education-types`,
        req: actions.getEducationTypes.getEducationTypesReq,
        ok: actions.getEducationTypes.getEducationTypesOk,
        fail: actions.getEducationTypes.getEducationTypesFail,
      }),
    );
  };
}

const setWithLocale = (__LOCALE__, tranlations) => ({
  __LOCALE__,
  ...tranlations,
});

export function getLanguages(lang) {
  const l = lang.toUpperCase();
  return (dispatch) => {
    return dispatch(
      actions.getLanguages.languageOk(setWithLocale(l, languages[l])),
    );
  };
}

export function getLanguageOptions() {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/languages`,
        req: actions.getLanguageOptions.getLanguageOptionsReq,
        ok: actions.getLanguageOptions.getLanguageOptionsOk,
        fail: actions.getLanguageOptions.getLanguageOptionsFail,
      }),
    );
  };
}

export function getDocuments(ownerId) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/documents${
          ownerId ? `?ownerId=${ownerId}` : ''
        }`,
        req: actions.getDocuments.getDocumentsReq,
        ok: actions.getDocuments.getDocumentsOk,
        fail: actions.getDocuments.getDocumentsFail,
      }),
    );
  };
}

export function postDocuments(files) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/documents`,
        req: actions.postDocuments.postDocumentsReq,
        ok: actions.postDocuments.postDocumentsOk,
        fail: actions.postDocuments.postDocumentsFail,
        payload: files,
      }),
    );
  };
}

export function postApplicationReviews(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/application-reviews`,
        req: actions.postApplicationReviews.postApplicationReviewsReq,
        ok: actions.postApplicationReviews.postApplicationReviewsOk,
        fail: actions.postApplicationReviews.postApplicationReviewsFail,
        payload: data,
      }),
    );
  };
}

export function updateDocuments(id, data, cType) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'put',
        cType,
        url: `${config.apiUrl}/documents/${id}`,
        req: actions.updateDocuments.updateDocumentsReq,
        ok: actions.updateDocuments.updateDocumentsOk,
        fail: actions.updateDocuments.updateDocumentsFail,
        payload: data,
      }),
    );
  };
}

// variant: 'success'|'warning'|'error'|'info'
export function notifyUser(variant, message, title = null) {
  return (dispatch) => {
    dispatch(actions.notification.addNotification(variant, message, title));
  };
}

export function removeNotification(key) {
  return (dispatch) => {
    dispatch(actions.notification.removeNotification(key));
  };
}

export function getApplicationFilters(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/applications/filters`,
        req: actions.getApplicationFilters.getApplicationFiltersReq,
        ok: actions.getApplicationFilters.getApplicationFiltersOk,
        fail: actions.getApplicationFilters.getApplicationFiltersFail,
        payload: data,
      }),
    );
  };
}

export function closeApplication({ id, closedReason, applicationStatusId }) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'patch',
        url: `${config.apiUrl}/v2/applications/${id}`,
        req: actions.closeApplication.closeApplicationReq,
        ok: actions.closeApplication.closeApplicationOk,
        fail: actions.closeApplication.closeApplicationFail,
        payload: { closeReason: closedReason, applicationStatusId },
      }),
    );
  };
}

// export function getContractStatuses() {
//   return (dispatch) => {
//     return dispatch(
//       axiosCall({
//         method: 'get',
//         url: `${config.apiUrl}/contract-statuses`,
//         req: actions.getContractStatuses.getContractStatusesReq,
//         ok: actions.getContractStatuses.getContractStatusesOk,
//         fail: actions.getContractStatuses.getContractStatusesFail,
//       }),
//     );
//   };
// }

export function triggerEvent(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/events`,
        req: actions.triggerEvent.triggerEventReq,
        ok: actions.triggerEvent.triggerEventOk,
        fail: actions.triggerEvent.triggerEventFail,
        payload: data,
      }),
    );
  };
}

export function getApplicationUpdateRequests(id) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/application-update-requests/${id}`,
        req: actions.getApplicationUpdateRequests
          .getApplicationUpdateRequestsReq,
        ok: actions.getApplicationUpdateRequests.getApplicationUpdateRequestsOk,
        fail: actions.getApplicationUpdateRequests
          .getApplicationUpdateRequestsFail,
      }),
    );
  };
}

export function postApplicationUpdateRequests(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'post',
        url: `${config.apiUrl}/application-update-requests/${data.id}`,
        req: actions.postApplicationUpdateRequests
          .postApplicationUpdateRequestsReq,
        ok: actions.postApplicationUpdateRequests
          .postApplicationUpdateRequestsOk,
        fail: actions.postApplicationUpdateRequests
          .postApplicationUpdateRequestsFail,
        payload: {
          updateRequest: data.updateRequest,
          feedbackFrom: data.feedbackFrom,
          subject: data.subject,
          details: data.details,
        },
      }),
    );
  };
}

export function deleteApplicationUpdateRequests(data) {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'delete',
        url: `${config.apiUrl}/application-update-requests/${data.id}`,
        req: actions.deleteApplicationUpdateRequests
          .deleteApplicationUpdateRequestsReq,
        ok: actions.deleteApplicationUpdateRequests
          .deleteApplicationUpdateRequestsOk,
        fail: actions.deleteApplicationUpdateRequests
          .deleteApplicationUpdateRequestsFail,
        payload: {
          feedbackFrom: data.feedbackFrom,
          subject: data.subject,
          details: data.details,
        },
      }),
    );
  };
}

export const getKnownVulnerabilitiesOptions = () => {
  return (dispatch) => {
    return dispatch(
      axiosCall({
        method: 'get',
        url: `${config.apiUrl}/known-vulnerabilities`,
        req: actions.getKnownVulnerabilitiesOptions
          .getKnownVulnerabilitiesOptionsReq,
        ok: actions.getKnownVulnerabilitiesOptions
          .getKnownVulnerabilitiesOptionsOk,
        fail: actions.getKnownVulnerabilitiesOptions
          .getKnownVulnerabilitiesOptionsFail,
      }),
    );
  };
};
